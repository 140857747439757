import { FC, useMemo } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Stack from '@mui/material/Stack';

import { IShopListDTO, WalletType } from 'types';
import { translate } from 'utils/translate';

type BalanceGroupProps = {
  betShop?: IShopListDTO | null;
  pos?: IShopListDTO | null;
  betShopWallet?: WalletType | null;
  posWallet?: WalletType | null;
};

const BalanceGroup: FC<BalanceGroupProps> = ({ betShop, pos, betShopWallet, posWallet }) => {
  if (!betShop) {
    return <></>;
  }
  const betShopInfo = useMemo(() => {
    const info = {
      id: betShop?.id,
      name: betShop?.name || '',
      currencyCode: betShopWallet?.currencyCode || '',
      balance: betShopWallet?.balance.toLocaleString() || '0',
    };
    return info;
  }, [betShop, betShopWallet]);

  const posInfo = useMemo(() => {
    const info = {
      id: pos?.id,
      name: pos?.name || '',
      currencyCode: posWallet?.currencyCode || '',
      balance: posWallet?.balance.toLocaleString() || '0',
    };
    return info;
  }, [pos, posWallet]);

  return (
    <TableContainer component={Paper}>
      <Table size={'small'} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={12}>
              {translate('Balance Info')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={12}>
              {betShopInfo.name && betShopInfo.id ? (
                <Stack direction="row" justifyContent="flex-start">
                  {betShopInfo.name && betShopInfo.id ? `${betShopInfo.name} (${betShopInfo.id})` : ''}
                  {`: ${betShopInfo.balance || 0} ${betShopInfo.currencyCode}`}
                </Stack>
              ) : (
                ''
              )}
            </TableCell>
          </TableRow>
          {pos && (
            <TableRow>
              <TableCell colSpan={12}>
                {posInfo.name && posInfo.id ? (
                  <Stack direction="row" justifyContent="flex-start">
                    {`${posInfo.name} (${posInfo.id})`}
                    {`: ${posInfo.balance || 0} ${posInfo.currencyCode}`}
                  </Stack>
                ) : (
                  ''
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BalanceGroup;
