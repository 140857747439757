const sx = {
  root: {
    width: '100vw',
    height: 60,
    borderTopWith: '1px',
    borderTopStyle: 'solid',
    borderTopColor: 'custom.neutrals.scale.70',
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2,
    '& svg': {
      width: 30,
      height: 30,
    },
  },
  head: {
    px: 3,
    width: '100vw',
    height: 60,
    bgcolor: 'custom.neutrals.scale.900',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    py: 3,
    px: 2,
    overflowY: 'auto',
  },
};

export default sx;
