import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { Stack, Box, SelectChangeEvent } from '@mui/material';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import TransferIcon from 'components/common/icons/bulk/Transfer';
import Form, { FormNumberInput, FormSelect, Autocomplete } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { transferBetShopMoneySchema, transferPosMoneySchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { CUSTOM_TRANSFER_TYPE_OPTIONS } from 'configs';
import { PUT_TRANSFER_BET_SHOP_MONEY, PUT_TRANSFER_POS_MONEY } from 'api/paths/constants';
import { ITransferMoney, WalletType } from 'types/transfer';
import { createTransferMoney } from 'api/requests/transfer';
import { OptionType } from 'types/utils';
import { ApiErrorType, IShopListDTO } from 'types';
import useScreen from 'hooks/useScreen';
import useAuth from 'store/auth';
import BalanceGroup from 'components/common/BalanceGroup';
import { getPosTransfers, getShopWallets } from 'api/requests/shops';
import useMount from 'hooks/useMount';
import { getBetShops } from 'api/requests/betShop';

const prepareTransferEndpoint = (walletId: string, permission: CashierPermissionsEnum): string => {
  if (permission === CashierPermissionsEnum.PARTNER_ADMIN) {
    return PUT_TRANSFER_BET_SHOP_MONEY(walletId);
  } else if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
    return PUT_TRANSFER_POS_MONEY(walletId);
  }

  return '';
};

interface Props {
  onClose: () => void;
}

const TransferModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [betShops, setBetShops] = useState<IShopListDTO[]>([]);
  const [posTransfers, setPosTransfers] = useState<IShopListDTO[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<WalletType[]>([]);
  const [betShopWallet, setBetShopWallet] = useState<WalletType | null>(null);
  const [betShopWalletOptions, setBetShopWalletOptions] = useState<WalletType[]>([]);
  const [posWallet, setPosWallet] = useState<WalletType | null>(null);
  const [authState] = useAuth();
  const notify = useToaster();
  const permission = authState?.data?.cashier?.permission;
  const hasPos = permission === CashierPermissionsEnum.BET_SHOP_ADMIN;

  interface ITransfer extends Omit<ITransferMoney, 'betShop' | 'pos'> {
    betShop: string;
    pos: string;
  }

  const resolver = useYupValidationResolver<Partial<ITransfer>>(
    (hasPos ? transferPosMoneySchema : transferBetShopMoneySchema) as unknown as ObjectSchema<Partial<ITransfer>>,
  );
  const { control, watch, trigger, setValue, handleSubmit } = useForm<Partial<ITransfer>>({
    resolver,
    defaultValues: {
      betShop: undefined,
      pos: undefined,
      walletId: '',
      type: CUSTOM_TRANSFER_TYPE_OPTIONS?.[0]?.id,
      amount: undefined,
    },
  });

  const betShop = watch('betShop');
  const pos = watch('pos');

  const selectedBetShop = useMemo(() => {
    return betShops.find(({ id }) => String(id) === betShop);
  }, [betShop, betShops]);

  const selectedPos = useMemo(() => {
    return posTransfers.find(({ id }) => String(id) === pos);
  }, [pos, posTransfers]);

  useMount(() => {
    (async (): Promise<void> => {
      try {
        const res = await getBetShops({ page: '1', size: String(Number.MAX_SAFE_INTEGER) });
        setBetShops(res.data.data);
      } catch (error) {
        console.error(error);
      }
    })();
  });

  useEffect(() => {
    if (betShop) {
      (async (): Promise<void> => {
        try {
          const res = await getPosTransfers({ betShopId: betShop, page: '1', size: String(Number.MAX_SAFE_INTEGER) });
          setPosTransfers(res.data.data);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [betShop]);

  const handleCreateSubmit = async (data: Partial<ITransfer>): Promise<void> => {
    setIsLoading(true);
    if (!data.betShop) {
      return;
    }

    try {
      const endpoint = prepareTransferEndpoint(data.walletId as string, permission as CashierPermissionsEnum);
      await createTransferMoney(
        {
          amount: data.amount,
          type: data.type,
        },
        endpoint,
      );
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const getShopCurrencies = async (id: string): Promise<void> => {
    try {
      const response = await getShopWallets(id);
      const wallets = response.data ?? [];
      setCurrencyOptions([
        ...(wallets?.map((i: WalletType) => {
          return { name: i.currencyCode, id: i.id, balance: i.balance, currencyCode: i.currencyCode };
        }) || []),
      ] as unknown as WalletType[]);
      setValue('walletId', (wallets[0]?.id || '') as string);
      hasPos ? setPosWallet(wallets[0] || null) : setBetShopWallet(wallets[0] || null);
      await trigger('walletId');
    } catch (error) {
      console.error(error);
    }
  };

  const handleBetShopChange = async (id: string): Promise<void> => {
    if (!hasPos) {
      await getShopCurrencies(id);
    } else {
      const response = await getShopWallets(id);
      const wallets = response.data ?? [];
      setBetShopWalletOptions(wallets);
      setBetShopWallet(wallets[0] || null);
      setValue('pos', undefined);
    }
  };

  const handlePosChange = async (id: string): Promise<void> => {
    await getShopCurrencies(id);
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  const handleChangeCurrencyCode = useCallback(
    (event: SelectChangeEvent<unknown>): void => {
      const selectedWallet = currencyOptions.find((i) => i.id === event.target.value);
      if (hasPos) {
        setPosWallet(selectedWallet || null);
        const selectedBetShopWallet: WalletType | undefined = betShopWalletOptions.find(
          (i) => i.currencyCode === selectedWallet?.currencyCode,
        );
        setBetShopWallet(selectedBetShopWallet || null);
      } else {
        setBetShopWallet(selectedWallet || null);
      }
    },
    [hasPos, currencyOptions, betShopWalletOptions],
  );

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<TransferIcon />}
      title="Money Transfer"
      isLoading={isLoading}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Stack spacing={2}>
          <Form onSubmit={handleSubmit(handleCreateSubmit)}>
            <Stack spacing={2}>
              <Autocomplete
                required
                options={betShops}
                name="betShop"
                control={control}
                displayProp="name"
                label="Bet Shops"
                onChange={handleBetShopChange}
              />
              {hasPos && (
                <Autocomplete
                  required
                  options={posTransfers}
                  name="pos"
                  control={control}
                  displayProp="name"
                  label="POS"
                  onChange={handlePosChange}
                />
              )}
              <FormSelect
                required
                control={control}
                name="walletId"
                label="Currency"
                onChange={handleChangeCurrencyCode}
                options={currencyOptions as unknown as OptionType[]}
              />
              <FormSelect required control={control} name="type" label="Type" options={CUSTOM_TRANSFER_TYPE_OPTIONS} />
              <FormNumberInput required control={control} name="amount" label="Amount" />
            </Stack>
          </Form>
          <BalanceGroup
            betShop={selectedBetShop}
            pos={selectedPos}
            betShopWallet={betShopWallet}
            posWallet={posWallet}
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default TransferModal;
