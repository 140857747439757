import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const ConfigurationsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.4"
      d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2Z"
      fill="inherit"
    />
    <path
      d="M16.55 7.45995C15.29 6.19995 14.07 6.16995 12.78 7.45995L11.99 8.24995C11.92 8.31995 11.9 8.41995 11.92 8.50995C12.41 10.2299 13.78 11.5999 15.5 12.0899C15.52 12.0999 15.55 12.0999 15.58 12.0999C15.65 12.0999 15.72 12.0699 15.77 12.0199L16.55 11.2399C17.19 10.5999 17.5 9.98994 17.5 9.36995C17.5 8.71994 17.19 8.09995 16.55 7.45995Z"
      fill="inherit"
    />
    <path
      d="M14.34 12.6699C14.15 12.5799 13.97 12.4899 13.8 12.3799C13.66 12.2999 13.52 12.2099 13.39 12.1099C13.28 12.0399 13.15 11.9399 13.02 11.8299C13 11.8199 12.96 11.7899 12.91 11.7299C12.7 11.5499 12.46 11.3099 12.24 11.0599C12.22 11.0499 12.19 10.9999 12.15 10.9399C12.08 10.8599 11.98 10.7299 11.88 10.5899C11.8 10.4899 11.71 10.3499 11.63 10.2099C11.53 10.0399 11.43 9.85987 11.35 9.67987C11.26 9.47987 11.19 9.29987 11.12 9.11987L7.35001 12.8899C7.10001 13.1399 6.87001 13.5999 6.82001 13.9399L6.53 16.0199C6.46 16.4599 6.58999 16.8699 6.85999 17.1499C7.08999 17.3799 7.41001 17.4999 7.76001 17.4999C7.84001 17.4999 7.90999 17.4899 7.98999 17.4799L10.07 17.1899C10.41 17.1399 10.87 16.9099 11.12 16.6599L14.89 12.8899C14.71 12.8199 14.53 12.7499 14.34 12.6699Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ConfigurationsIcon;
