import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const BetShopIconIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.4"
      d="M21.37 11.3899V17.3799C21.37 20.1399 19.13 22.3799 16.37 22.3799H7.63C4.87 22.3799 2.63 20.1399 2.63 17.3799V11.4599C3.39 12.2799 4.47 12.7499 5.64 12.7499C6.9 12.7499 8.11 12.1199 8.87 11.1099C9.55 12.1199 10.71 12.7499 12 12.7499C13.28 12.7499 14.42 12.1499 15.11 11.1499C15.88 12.1399 17.07 12.7499 18.31 12.7499C19.52 12.7499 20.62 12.2599 21.37 11.3899Z"
      fill="inherit"
    />
    <path
      d="M14.99 1.25H8.98997L8.24997 8.61C8.18997 9.29 8.28997 9.93 8.53997 10.51C9.11997 11.87 10.48 12.75 12 12.75C13.54 12.75 14.87 11.89 15.47 10.52C15.65 10.09 15.76 9.59 15.77 9.08V8.89L14.99 1.25Z"
      fill="inherit"
    />
    <path
      opacity="0.6"
      d="M22.36 8.27L22.07 5.5C21.65 2.48 20.28 1.25 17.35 1.25H13.51L14.25 8.75C14.26 8.85 14.27 8.96 14.27 9.15C14.33 9.67 14.49 10.15 14.73 10.58C15.45 11.9 16.85 12.75 18.31 12.75C19.64 12.75 20.84 12.16 21.59 11.12C22.19 10.32 22.46 9.31 22.36 8.27Z"
      fill="inherit"
    />
    <path
      opacity="0.6"
      d="M6.59002 1.25C3.65002 1.25 2.29002 2.48 1.86002 5.53L1.59002 8.28C1.49002 9.35 1.78002 10.39 2.41002 11.2C3.17002 12.19 4.34002 12.75 5.64002 12.75C7.10002 12.75 8.50002 11.9 9.21002 10.6C9.47002 10.15 9.64002 9.63 9.69002 9.09L10.47 1.26H6.59002V1.25Z"
      fill="inherit"
    />
    <path
      d="M11.35 16.6602C10.08 16.7902 9.12 17.8702 9.12 19.1502V22.3802H14.87V19.5002C14.88 17.4102 13.65 16.4202 11.35 16.6602Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default BetShopIconIcon;
