import { FC, ReactNode } from 'react';
import { Box, Button, Dialog, DialogContent, Divider, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { translate, TranslationItem, TranslationKey } from 'utils/translate';

import RemoveIcon from '../icons/Remove';

type ColorTypes = 'info' | 'error';

interface Props {
  title: TranslationItem;
  icon: ReactNode;
  isOpen?: boolean;
  maxWidth: number;
  fullScreen?: boolean;
  saveText?: TranslationItem;
  onSave?: () => void;
  isLoading?: boolean;
  cancelText?: TranslationItem;
  onClose: () => void;
  children: ReactNode;
  isSaveDisabled?: boolean;
  color?: ColorTypes;
  onBack?: () => void;
  showBack?: boolean;
}

const iconColorsVariants: Record<ColorTypes, { color: string; bgcolor: string }> = {
  info: {
    color: 'custom.primary.scale.600',
    bgcolor: 'custom.primary.scale.100',
  },
  error: {
    color: 'custom.red.scale.500',
    bgcolor: 'custom.red.scale.100',
  },
};

const Modal: FC<Props> = ({
  color,
  icon,
  title,
  onSave,
  onClose,
  children,
  maxWidth,
  fullScreen = false,
  isLoading,
  isOpen = true,
  saveText = 'Save' as TranslationKey,
  cancelText = 'Cancel' as TranslationKey,
  isSaveDisabled = false,
  showBack = false,
  onBack,
}) => {
  const iconColors = (color && iconColorsVariants?.[color]) || iconColorsVariants?.info;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth,
        },
      }}
    >
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {onBack && showBack && (
              <IconButton
                onClick={onBack}
                disabled={isLoading}
                sx={{
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 0,
                }}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            )}
            <Box
              sx={{
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                color: iconColors.color,
                bgcolor: iconColors.bgcolor,
              }}
            >
              {icon}
            </Box>
          </Box>
          <IconButton onClick={onClose} disabled={isLoading}>
            <RemoveIcon />
          </IconButton>
        </Box>
        <Typography variant="subtitle1" fontWeight={600} mt={1.5} mb={3}>
          {translate(title)}
        </Typography>
        {children}
      </DialogContent>
      <Divider sx={{ mt: 2 }} />
      <Stack p={3} spacing={1.5} direction="row" justifyContent="flex-end" alignItems="center">
        <Button variant="outlined" color="inherit" disabled={isLoading} onClick={onClose}>
          {translate(cancelText)}
        </Button>
        {onSave && (
          <Button variant="contained" color={color} onClick={onSave} disabled={isLoading || isSaveDisabled}>
            {translate(saveText)}
          </Button>
        )}
      </Stack>
    </Dialog>
  );
};

export default Modal;
