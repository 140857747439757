import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Box } from '@mui/material';

import LockIcon from 'components/common/icons/bulk/Lock';
import Modal from 'components/common/Modal';
import Form, { FormInput } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { changePasswordSchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { changePassword } from 'api/requests/account';
import { ChangePasswordFormType } from 'types/utils';
import { ApiErrorType } from 'types';
import useScreen from 'hooks/useScreen';
import useToaster from 'hooks/useToaster';

interface Props {
  onClose: () => void;
}

const ChangePasswordModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const notify = useToaster();

  const resolver = useYupValidationResolver<ChangePasswordFormType>(changePasswordSchema);
  const { control, reset, handleSubmit } = useForm<ChangePasswordFormType>({
    resolver,
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const handleBack = (): void => {
    reset({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  const handleFormSubmit = async (data: ChangePasswordFormType): Promise<void> => {
    setIsLoading(true);
    if (!data) {
      return;
    }
    try {
      await changePassword({
        newPassword: data.newPassword,
        oldPassword: data.oldPassword,
      });
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleFormSubmit)();
  };

  return (
    <Modal
      maxWidth={480}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<LockIcon />}
      title="Change Password"
      isLoading={isLoading}
      onBack={handleBack}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInput required label="Current Password" control={control} name="oldPassword" type="password" />
            </Grid>
            <Grid item xs={12}>
              <FormInput required label="New Password" control={control} name="newPassword" type="password" />
            </Grid>
            <Grid item xs={12}>
              <FormInput required label="Confirm password" control={control} name="confirmPassword" type="password" />
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
