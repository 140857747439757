import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CashierShopTypeEnum } from 'enums/entity';

import {
  GET_BET_SHOPS_INFO,
  POST_BET_SHOP_INFO,
  PUT_BET_SHOP_INFO,
  DELETE_BET_SHOP_INFO,
  GET_POS_INFO,
  POST_POS_INFO,
  PUT_POS_INFO,
  DELETE_POS_INFO,
  PUT_PAYMENT_METHOD_INFO,
  POST_PAYMENT_METHOD_INFO,
  GET_PAYMENT_METHOD_INFO,
  DELETE_PAYMENT_METHOD_INFO,
} from 'api/paths/constants';
import { ApiResponseType, CustomFieldType } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY } from 'constant';

type UseConfigurationStateType = {
  posFields: CustomFieldType[];
  betShoFields: CustomFieldType[];
  paymentMethodFields: CustomFieldType[];
  isLoading: boolean;
};

type UseConfigurationActionsType = {
  getBetShop: () => Promise<void>;
  createBetShopField: (data: Omit<CustomFieldType, 'id'>) => Promise<void>;
  updateBetShopField: (data: CustomFieldType) => Promise<void>;
  deleteBetShopField: (id: number, type: CashierShopTypeEnum) => Promise<void>;
  getPos: () => Promise<void>;
  createPosField: (data: Omit<CustomFieldType, 'id'>) => Promise<void>;
  updatePosField: (data: CustomFieldType) => Promise<void>;
  deletePosField: (id: number, type: CashierShopTypeEnum) => Promise<void>;
  getPaymentMethod: (id: number) => Promise<void>;
  createPaymentMethodField: (data: Omit<CustomFieldType & { partnerMethodId: number }, 'id'>) => Promise<void>;
  updatePaymentMethodField: (data: CustomFieldType) => Promise<void>;
  deletePaymentMethodField: (id: number, type: CashierShopTypeEnum) => Promise<void>;
};

const initialState: UseConfigurationStateType = {
  posFields: EMPTY_ARRAY,
  betShoFields: EMPTY_ARRAY,
  paymentMethodFields: EMPTY_ARRAY,
  isLoading: false,
};

const getBetShopCustomFields = createAsyncThunk<ApiResponseType<CustomFieldType>>(
  'cashiers/getBetShopCustomFields',
  async () => {
    return fetcher({
      url: GET_BET_SHOPS_INFO,
    });
  },
);

const getPosCustomFields = createAsyncThunk<ApiResponseType<CustomFieldType>>(
  'cashiers/getPosCustomFields',
  async () => {
    return fetcher({
      url: GET_POS_INFO,
    });
  },
);

const getPaymentMethodCustomFields = createAsyncThunk<ApiResponseType<CustomFieldType>, number>(
  'cashiers/getPaymentMethodCustomFields',
  async (id: number) => {
    return fetcher({
      url: GET_PAYMENT_METHOD_INFO(id),
    });
  },
);

const createBetShopField = createAsyncThunk<ApiResponseType<CustomFieldType>, Omit<CustomFieldType, 'id'>>(
  'cashiers/createBetShopField',
  async (body: Omit<CustomFieldType, 'id'>) => {
    return fetcher({
      url: POST_BET_SHOP_INFO,
      method: 'POST',
      body,
    });
  },
);

const createPosField = createAsyncThunk<ApiResponseType<CustomFieldType>, Omit<CustomFieldType, 'id'>>(
  'cashiers/createPosField',
  async (body: Omit<CustomFieldType, 'id'>) => {
    return fetcher({
      url: POST_POS_INFO,
      method: 'POST',
      body,
    });
  },
);

const createPaymentMethodField = createAsyncThunk<
  ApiResponseType<CustomFieldType>,
  Omit<CustomFieldType & { partnerMethodId: number }, 'id'>
>('cashiers/createPaymentMethodField', async (body: Omit<CustomFieldType, 'id'>) => {
  return fetcher({
    url: POST_PAYMENT_METHOD_INFO,
    method: 'POST',
    body,
  });
});

const updateBetShopField = createAsyncThunk<ApiResponseType<CustomFieldType>, CustomFieldType>(
  'cashiers/updateBetShopField',
  async (body: CustomFieldType) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_BET_SHOP_INFO(id),
      method: 'PUT',
      body: rest,
    });
  },
);

const updatePosField = createAsyncThunk<ApiResponseType<CustomFieldType>, CustomFieldType>(
  'cashiers/updatePosField',
  async (body: CustomFieldType) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_POS_INFO(id),
      method: 'PUT',
      body: rest,
    });
  },
);

const updatePaymentMethodField = createAsyncThunk<ApiResponseType<CustomFieldType>, CustomFieldType>(
  'cashiers/updatePaymentMethodField',
  async (body: CustomFieldType) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_PAYMENT_METHOD_INFO(id),
      method: 'PUT',
      body: rest,
    });
  },
);

const deleteBetShopField = createAsyncThunk<ApiResponseType<CustomFieldType>, number>(
  'cashiers/deleteBetShopField',
  async (id: number) => {
    return fetcher({
      url: DELETE_BET_SHOP_INFO(id),
      method: 'DELETE',
    });
  },
);

const deletePosField = createAsyncThunk<ApiResponseType<CustomFieldType>, number>(
  'cashiers/deletePosField',
  async (id: number) => {
    return fetcher({
      url: DELETE_POS_INFO(id),
      method: 'DELETE',
    });
  },
);

const deletePaymentMethodField = createAsyncThunk<ApiResponseType<CustomFieldType>, number>(
  'cashiers/deletePosField',
  async (id: number) => {
    return fetcher({
      url: DELETE_PAYMENT_METHOD_INFO(id),
      method: 'DELETE',
    });
  },
);

const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    delete: (state, action) => {
      const { id, type } = action.payload;
      let propName: 'betShoFields' | 'posFields' | 'paymentMethodFields' | '' = '';
      if (type === CashierShopTypeEnum.BET_SHOP) {
        propName = 'betShoFields';
      } else if (type === CashierShopTypeEnum.POS) {
        propName = 'posFields';
      } else if (type === CashierShopTypeEnum.PAYMENT) {
        propName = 'paymentMethodFields';
      }

      if (propName) {
        state[propName] = state[propName].filter((i) => i.id !== id);
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        getBetShopCustomFields.fulfilled.type,
        (state, action: PayloadAction<{ data: CustomFieldType[] }>): void => {
          state.isLoading = false;
          state.betShoFields = action.payload?.data;
        },
      )
      .addCase(getPosCustomFields.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType[] }>): void => {
        state.isLoading = false;
        state.posFields = action.payload?.data;
      })
      .addCase(
        getPaymentMethodCustomFields.fulfilled.type,
        (state, action: PayloadAction<{ data: CustomFieldType[] }>): void => {
          state.isLoading = false;
          state.paymentMethodFields = action.payload?.data;
        },
      )
      .addCase(createBetShopField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.betShoFields = [action.payload?.data, ...state.betShoFields];
      })
      .addCase(createPosField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.posFields = [action.payload?.data, ...state.posFields];
      })
      .addCase(
        createPaymentMethodField.fulfilled.type,
        (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
          state.paymentMethodFields = [action.payload?.data, ...state.paymentMethodFields];
        },
      )
      .addCase(updateBetShopField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.betShoFields = state.betShoFields.map((i: CustomFieldType) =>
          i.id === action.payload?.data?.id ? { ...action.payload.data } : i,
        );
      })
      .addCase(updatePosField.fulfilled.type, (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
        state.posFields = state.posFields.map((i: CustomFieldType) =>
          i.id === action.payload?.data?.id ? { ...action.payload.data } : i,
        );
      })
      .addCase(
        updatePaymentMethodField.fulfilled.type,
        (state, action: PayloadAction<{ data: CustomFieldType }>): void => {
          state.paymentMethodFields = state.paymentMethodFields.map((i: CustomFieldType) =>
            i.id === action.payload?.data?.id ? { ...action.payload.data } : i,
          );
        },
      ),
});

const useConfiguration = (): [UseConfigurationStateType, UseConfigurationActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.configuration);
  const dispatch = useAppDispatch();

  const actions = {
    getBetShop: async (): Promise<void> => {
      await dispatch(getBetShopCustomFields());
    },
    createBetShopField: async (model: Omit<CustomFieldType, 'id'>): Promise<void> => {
      await dispatch(createBetShopField(model));
    },
    updateBetShopField: async (model: CustomFieldType): Promise<void> => {
      await dispatch(updateBetShopField(model));
    },
    deleteBetShopField: async (id: number, type: CashierShopTypeEnum): Promise<void> => {
      await dispatch(deleteBetShopField(id));
      dispatch(configurationSlice.actions.delete({ id: id, type: type }));
    },
    getPos: async (): Promise<void> => {
      await dispatch(getPosCustomFields());
    },
    createPosField: async (model: Omit<CustomFieldType, 'id'>): Promise<void> => {
      await dispatch(createPosField(model));
    },
    updatePosField: async (model: CustomFieldType): Promise<void> => {
      await dispatch(updatePosField(model));
    },
    deletePosField: async (id: number, type: CashierShopTypeEnum): Promise<void> => {
      await dispatch(deletePosField(id));
      dispatch(configurationSlice.actions.delete({ id: id, type: type }));
    },
    getPaymentMethod: async (id: number): Promise<void> => {
      await dispatch(getPaymentMethodCustomFields(id));
    },
    createPaymentMethodField: async (
      model: Omit<CustomFieldType & { partnerMethodId: number }, 'id'>,
    ): Promise<void> => {
      await dispatch(createPaymentMethodField(model));
    },
    updatePaymentMethodField: async (model: CustomFieldType): Promise<void> => {
      await dispatch(updatePaymentMethodField(model));
    },
    deletePaymentMethodField: async (id: number, type: CashierShopTypeEnum): Promise<void> => {
      await dispatch(deletePaymentMethodField(id));
      dispatch(configurationSlice.actions.delete({ id: id, type: type }));
    },
  };

  return [state, actions];
};

export { configurationSlice };

export default useConfiguration;
