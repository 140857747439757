import { CashierShopTypeEnum } from 'enums/entity';

import { SHOP_PAGE_TYPE } from 'constant';

type ShopPageType = typeof SHOP_PAGE_TYPE;

export const getShopTypeByLastPartUrl = (url: string): CashierShopTypeEnum => {
  return SHOP_PAGE_TYPE[url.slice(url.lastIndexOf('/'), url.length) as keyof ShopPageType];
};

export const getShopTypeByFirstPartUrl = (url: string): CashierShopTypeEnum => {
  return SHOP_PAGE_TYPE[`/${url.split('/')[1]}` as keyof ShopPageType];
};
