import { FC, useState, useMemo, MouseEvent } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Box, Stack, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import { ObjectSchema, Schema } from 'yup';
import { CashierShopTypeEnum, EntityEnum } from 'enums/entity';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { ENTITY_TYPES_INFO } from 'constant';
import Modal from 'components/common/Modal';
import AddIcon from 'components/common/icons/bulk/AddIcon';
import Form from 'components/common/Form';
import { translate, TranslationKey } from 'utils/translate';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { ApiErrorType, IUserListDTO, IShopListDTO, IShopCreateDTO, IUserCreateDTO, CustomFieldType } from 'types';
import fetcher from 'utils/fetcher';
import { POST_POS, POST_CASHIER, POST_BET_SHOP } from 'api/paths/constants';
import useScreen from 'hooks/useScreen';
import { AccessCreateUserPermissions, AccessCreateShopPermissions } from 'utils/accessTemplates';
import useUser from 'store/user';
import useShop from 'store/shop';
import useConfiguration from 'store/configuration';
import useToaster from 'hooks/useToaster';
import useDidMount from 'hooks/useDidMount';
import { createYupSchema } from 'configs/validationSchemes';
import { getShopTypeByLastPartUrl } from 'utils/shop';
import { getUserTypeByLastPartUrl } from 'utils/user';

import CreateUserFields from './CreateUserFields';
import { getShopSchema, getUserSchema, CreateShopDefaultValues, CreateUserDefaultValues } from './CreateModal.helper';
import CreateShopFields from './CreateShopFields';

type Props = {
  onClose: () => void;
  permission: CashierPermissionsEnum;
};

const CreateModal: FC<Props> = ({ onClose, permission }) => {
  const { isMobile } = useScreen();
  const [, userActions] = useUser();
  const [, shopActions] = useShop();
  const location = useLocation();
  const shopType = getShopTypeByLastPartUrl(location.pathname);
  const userType = getUserTypeByLastPartUrl(location.pathname);
  const [configurationState, configurationActions] = useConfiguration();
  const [isLoading, setIsLoading] = useState(false);
  const hasTwoStep =
    permission === CashierPermissionsEnum.BET_SHOP_ADMIN || permission === CashierPermissionsEnum.POS_ADMIN;
  const [createType, setCreateType] = useState<EntityEnum | null>(hasTwoStep ? null : EntityEnum.USER);
  const notify = useToaster();

  const fields = useMemo(() => {
    if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
      return configurationState.betShoFields;
    } else if (permission === CashierPermissionsEnum.POS_ADMIN) {
      return configurationState.posFields;
    }
    return [];
  }, [configurationState, permission]);

  const resolver = useYupValidationResolver<Partial<IShopCreateDTO | IUserCreateDTO>>(
    (createType === EntityEnum.SHOP
      ? createYupSchema(
          (fields ?? []) as CustomFieldType[],
          getShopSchema(permission as CashierPermissionsEnum) as ObjectSchema<Schema>,
        )
      : getUserSchema(permission as CashierPermissionsEnum)) as ObjectSchema<Partial<IShopCreateDTO | IUserCreateDTO>>,
  );
  const { control, reset, handleSubmit } = useForm<Partial<IShopCreateDTO | IUserCreateDTO>>({
    resolver,
    defaultValues: !createType
      ? {}
      : createType === EntityEnum.SHOP
        ? {
            ...CreateShopDefaultValues[permission],
          }
        : {
            ...CreateUserDefaultValues[permission],
          },
  });

  useDidMount(() => {
    if (createType !== EntityEnum.SHOP) {
      return;
    }

    (async (): Promise<void> => {
      try {
        if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
          await configurationActions.getBetShop();
        } else if (permission === CashierPermissionsEnum.POS_ADMIN) {
          await configurationActions.getPos();
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [createType, permission]);

  const handleBack = (): void => {
    setCreateType(null);
    reset(
      createType === EntityEnum.SHOP
        ? {
            ...CreateShopDefaultValues[permission],
          }
        : {
            ...CreateUserDefaultValues[permission],
          },
    );
  };

  const handleChangeType = (event: MouseEvent<HTMLElement>, newAlignment: EntityEnum): void => {
    setCreateType(newAlignment);
  };

  const addToShopStore = useMemo(() => {
    if (shopType === CashierShopTypeEnum.BET_SHOP && permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
      return true;
    }

    if (shopType === CashierShopTypeEnum.POS && permission === CashierPermissionsEnum.POS_ADMIN) {
      return true;
    }

    return false;
  }, [permission, shopType]);

  const addToUserStore = useMemo(() => {
    if (userType === CashierPermissionsEnum.PARTNER_ADMIN && permission === CashierPermissionsEnum.SUPER_ADMIN) {
      return true;
    }

    if (userType === CashierPermissionsEnum.BET_SHOP_ADMIN && permission === CashierPermissionsEnum.PARTNER_ADMIN) {
      return true;
    }

    if (userType === CashierPermissionsEnum.POS_ADMIN && permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
      return true;
    }

    if (userType === CashierPermissionsEnum.USER && permission === CashierPermissionsEnum.POS_ADMIN) {
      return true;
    }

    return false;
  }, [permission, userType]);

  const handleCreateSubmit = async (data: Partial<IShopCreateDTO | IUserCreateDTO>): Promise<void> => {
    if (!createType) {
      return;
    }

    const requestObj = {
      ...data,
    };

    if (data.shopId) {
      requestObj.shopId = data.shopId;
    }

    const customFieldObj: FieldValues = {};
    fields.forEach((i) => {
      const key = i.key as keyof FieldValues;
      customFieldObj[key] = (data as FieldValues)?.[key];
    });

    if (createType === EntityEnum.SHOP) {
      (requestObj as IShopCreateDTO).meta = fields?.length > 0 ? JSON.stringify(customFieldObj) : null;
    }

    try {
      setIsLoading(true);
      const endpoint =
        createType === EntityEnum.SHOP
          ? permission === CashierPermissionsEnum.POS_ADMIN
            ? POST_POS
            : POST_BET_SHOP
          : POST_CASHIER;
      const response = await fetcher<void, Partial<IShopCreateDTO | IUserCreateDTO>>({
        method: 'POST',
        url: endpoint,
        body: requestObj,
      });
      if (createType === EntityEnum.SHOP && addToShopStore) {
        shopActions.addShop(response?.data as unknown as IShopListDTO);
      }

      if (createType === EntityEnum.USER && addToUserStore) {
        userActions.addUser(response?.data as unknown as IUserListDTO);
      }

      setIsLoading(false);
      onClose();
      notify({
        message: 'Success',
        type: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Create"
      icon={<AddIcon />}
      title="Create New"
      isLoading={isLoading}
      showBack={!!createType && hasTwoStep}
      isSaveDisabled={!createType && hasTwoStep}
      onBack={handleBack}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Slide direction="right" in={!createType} mountOnEnter unmountOnExit timeout={{ appear: 0, enter: 0, exit: 0 }}>
          <Stack alignItems="center">
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={createType}
              onChange={handleChangeType}
              aria-label="Platform"
            >
              {AccessCreateShopPermissions.some((r) => r === permission) && (
                <ToggleButton value={EntityEnum.SHOP}>
                  {translate(ENTITY_TYPES_INFO[EntityEnum.SHOP].label)}
                </ToggleButton>
              )}
              {AccessCreateUserPermissions.some((r) => r === permission) && (
                <ToggleButton value={EntityEnum.USER}>
                  {translate(ENTITY_TYPES_INFO[EntityEnum.USER].label)}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Stack>
        </Slide>
        <Slide
          direction="right"
          in={!!createType}
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 300, enter: 300, exit: 0 }}
        >
          <Box>
            <Form onSubmit={handleSubmit(handleCreateSubmit)}>
              {createType === EntityEnum.USER && <CreateUserFields control={control} permission={permission} />}
              {createType === EntityEnum.SHOP && (
                <CreateShopFields control={control} fields={fields} permission={permission} />
              )}
            </Form>
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
};

export default CreateModal;
