import { GET_PLAYER_TRANSFER_DEPOSIT, POST_CREATE_VOUCHER } from 'api/paths/constants';
import { ApiResponseType, IVoucher } from 'types';
import fetcher from 'utils/fetcher';

export const createVoucher = (params: { amount: string; currencyCode: string }): Promise<ApiResponseType<IVoucher>> =>
  fetcher<IVoucher, { amount: string; currencyCode: string }>({
    method: 'POST',
    url: POST_CREATE_VOUCHER,
    body: params,
  });

export const getPlayerTransferDepositInfo = (playerId: number): Promise<ApiResponseType<IVoucher>> =>
  fetcher<IVoucher>({
    method: 'GET',
    url: GET_PLAYER_TRANSFER_DEPOSIT(playerId),
  });
