import type { Schema } from 'yup';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import {
  createBetShopAdminSchema,
  createCashierSchema,
  createPartnerAdminSchema,
  createPosAdminSchema,
  createShopBetShopSchema,
  createShopPosSchema,
} from 'configs/validationSchemes';

export const CreatePartnerAdmin = {
  username: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  currencyCodes: [],
};
export const CreateBetShopAdmin = {
  username: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  currencyCodes: [],
  shopId: '',
};

export const CreatePosAdmin = {
  username: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  currencyCodes: [],
  shopId: '',
};

export const CreateBetShop = {
  name: '',
  currencyCodes: [],
};

export const CreatePos = {
  name: '',
  currencyCodes: [],
  country: '',
  city: '',
  address: '',
  workHours: '',
};

export const CreateUserDefaultValues = {
  [CashierPermissionsEnum.SUPER_ADMIN]: CreatePartnerAdmin,
  [CashierPermissionsEnum.PARTNER_ADMIN]: CreateBetShopAdmin,
  [CashierPermissionsEnum.BET_SHOP_ADMIN]: CreatePosAdmin,
  [CashierPermissionsEnum.POS_ADMIN]: undefined,
  [CashierPermissionsEnum.USER]: undefined,
};

export const CreateShopDefaultValues = {
  [CashierPermissionsEnum.BET_SHOP_ADMIN]: CreateBetShop,
  [CashierPermissionsEnum.POS_ADMIN]: CreatePos,
  [CashierPermissionsEnum.SUPER_ADMIN]: undefined,
  [CashierPermissionsEnum.PARTNER_ADMIN]: undefined,
  [CashierPermissionsEnum.USER]: undefined,
};

export const getShopSchema = (permission: CashierPermissionsEnum): Schema => {
  if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
    return createShopBetShopSchema;
  }

  return createShopPosSchema;
};

export const getUserSchema = (permission: CashierPermissionsEnum): Schema => {
  if (permission === CashierPermissionsEnum.SUPER_ADMIN) {
    return createPartnerAdminSchema;
  }

  if (permission === CashierPermissionsEnum.PARTNER_ADMIN) {
    return createBetShopAdminSchema;
  }

  if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
    return createPosAdminSchema;
  }

  return createCashierSchema;
};
