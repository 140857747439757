import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const TrashIcon: FC<SvgIconProps> = (props): JSX.Element => (
  <SvgIcon {...props} sx={{ height: 24, width: 24 }} viewBox="0 0 24 24">
    <path
      d="M12.8337 9.16667V15.5833M9.16699 9.16667L9.16699 15.5833M3.66699 5.5H18.3337M16.5003 5.5V16.3167C16.5003 17.3434 16.5005 17.8569 16.3007 18.249C16.1249 18.594 15.8441 18.8744 15.4991 19.0502C15.107 19.25 14.5939 19.25 13.5672 19.25H8.43384C7.40708 19.25 6.89331 19.25 6.50114 19.0502C6.15618 18.8744 5.87591 18.594 5.70015 18.249C5.50033 17.8569 5.50033 17.3434 5.50033 16.3167V5.5H16.5003ZM14.667 5.5H7.33366C7.33366 4.64577 7.33366 4.21865 7.47321 3.88173C7.65929 3.43252 8.01595 3.07563 8.46517 2.88955C8.80208 2.75 9.22943 2.75 10.0837 2.75H11.917C12.7712 2.75 13.1983 2.75 13.5352 2.88955C13.9845 3.07563 14.3413 3.43252 14.5273 3.88173C14.6669 4.21865 14.667 4.64578 14.667 5.5Z"
      stroke="#F04438"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default TrashIcon;
