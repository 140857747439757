export enum ActivitySectionsEnum {
  REAL_TIME = 'realTime',
  PERIODICAL = 'periodical',
  BALANCE = 'balance',
  TRANSACTIONS = 'transactions',
}

export enum ActivityTypeEnum {
  BET_SHOP_COUNT = 'betShopCount',
  BET_SHOP_TOTAL_BALANCE = 'betShopTotalBalance',
  POS_COUNT = 'posCount',
  POS_TOTAL_BALANCE = 'posTotalBalance',
  TOTAL_DEPOSIT_AMOUNT = 'totalDepositAmount',
  TOTAL_DEPOSIT_COUNT = 'totalDepositCount',
  TOTAL_WITHDRAW_AMOUNT = 'totalWithdrawAmount',
  TOTAL_WITHDRAW_COUNT = 'totalWithdrawCount',
  TOTAL_DEPOSIT_PERCENTAGE = 'totalDepositPercentage',
  TOTAL_WITHDRAW_PERCENTAGE = 'totalWithdrawPercentage',
  PLAYER_REAL_BALANCE = 'playerRealBalance',
  PLAYER_BONUS_BALANCE = 'playerBonusBalance',
  TOTAL_WITHDRAW_OUT_AMOUNT = 'totalWithdrawOutAmount',
  TOTAL_WITHDRAW_OUT_COUNT = 'totalWithdrawOutCount',
  TOTAL_WITHDRAW_IN_AMOUNT = 'totalWithdrawInAmount',
  TOTAL_WITHDRAW_IN_COUNT = 'totalWithdrawInCount',
  TOTAL_DEPOSIT_IN_AMOUNT = 'totalDepositInAmount',
  TOTAL_DEPOSIT_IN_COUNT = 'totalDepositInCount',
  TOTAL_DEPOSIT_OUT_AMOUNT = 'totalDepositOutAmount',
  TOTAL_DEPOSIT_OUT_COUNT = 'totalDepositOutCount',
  TOTAL_POS_BALANCE = 'totalPosBalance',
  TOTAL_POS_CREDIT = 'totalPosCredit',
  TOTAL_POS_CREDITS = 'totalPosCredits',
  POS_ADMIN_COUNT = 'posAdminCount',
  CASHIER_COUNT = 'cashierCount',
  TOTAL_DEPOSIT_PLAYER_AMOUNT = 'totalDepositPlayerAmount',
  TOTAL_DEPOSIT_PLAYER_COUNT = 'totalDepositPlayerCount',
  TOTAL_WITHDRAW_PLAYER_AMOUNT = 'totalWithdrawPlayerAmount',
  TOTAL_WITHDRAW_PLAYER_COUNT = 'totalWithdrawPlayerCount',
  TOTAL_BALANCE = 'totalBalance',
  TOTAL_CREDITS = 'totalCredits',
  TOTAL_BET_SHOP_BALANCE = 'totalBetShopBalance',
  TOTAL_BET_SHOP_CREDITS = 'totalBetShopCredits',
  TOTAL_DEPOSIT_COMMISSION_AMOUNT = 'totalDepositCommissionAmount',
  TOTAL_WITHDRAW_COMMISSION_AMOUNT = 'totalWithdrawCommissionAmount',
}

export enum PeriodEnum {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  LAST_WEEK = 'lastWeek',
  MONTH = 'month',
  LAST_MONTH = 'lastMonth',
}
