import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { USER_PAGE_TYPE } from 'constant';

type UserPageType = typeof USER_PAGE_TYPE;

export const getUserTypeByLastPartUrl = (url: string): CashierPermissionsEnum => {
  return USER_PAGE_TYPE[url.slice(url.lastIndexOf('/'), url.length) as keyof UserPageType];
};

export const getUserTypeByFirstPartUrl = (url: string): CashierPermissionsEnum => {
  return USER_PAGE_TYPE[`/${url.split('/')[1]}` as keyof UserPageType];
};
