import {
  GET_PLAYER_TRANSFER_DEPOSIT,
  POST_PLAYER_TRANSFER_DEPOSIT,
  GET_PLAYER_TRANSFER_WITHDRAW,
  POST_PLAYER_TRANSFER_WITHDRAW,
  POST_TRANSFER_AMOUNT,
} from 'api/paths/constants';
import { ApiResponseType, ITransferMoney, ITransferPlayerInfo, ITransferPlayerMoney, ITransferAmount } from 'types';
import fetcher from 'utils/fetcher';

export const createTransferMoney = (
  transfer: Partial<ITransferMoney>,
  endpoint: string,
): Promise<ApiResponseType<ITransferMoney>> =>
  fetcher<ITransferMoney, Partial<ITransferMoney>>({
    method: 'PUT',
    url: endpoint,
    body: transfer,
  });

export const getPlayerTransferDepositInfo = (playerId: number): Promise<ApiResponseType<ITransferPlayerInfo>> =>
  fetcher<ITransferPlayerInfo>({
    method: 'GET',
    url: GET_PLAYER_TRANSFER_DEPOSIT(playerId),
  });

export const createTransferPlayerDeposit = (
  transfer: Partial<ITransferPlayerMoney>,
): Promise<ApiResponseType<ITransferPlayerMoney>> =>
  fetcher<ITransferPlayerMoney, Partial<ITransferPlayerMoney>>({
    method: 'POST',
    url: POST_PLAYER_TRANSFER_DEPOSIT,
    body: transfer,
  });

export const getPlayerTransferWithdrawInfo = (
  playerId: number,
  code: string,
): Promise<ApiResponseType<ITransferPlayerInfo>> =>
  fetcher<ITransferPlayerInfo>({
    method: 'GET',
    url: GET_PLAYER_TRANSFER_WITHDRAW(playerId, code),
  });

export const createTransferPlayerWithdraw = (
  transfer: Partial<ITransferPlayerMoney>,
): Promise<ApiResponseType<ITransferPlayerMoney>> =>
  fetcher<ITransferPlayerMoney, Partial<ITransferPlayerMoney>>({
    method: 'POST',
    url: POST_PLAYER_TRANSFER_WITHDRAW,
    body: transfer,
  });

export const postTransferAmount = (
  id: number,
  transfer: Partial<ITransferAmount>,
): Promise<ApiResponseType<ITransferAmount>> =>
  fetcher<ITransferAmount, Partial<ITransferAmount>>({
    method: 'POST',
    url: POST_TRANSFER_AMOUNT(id),
    body: transfer,
  });
