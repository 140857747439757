import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const CloseIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.4"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="inherit"
    />
    <path
      d="M13.0601 11.9999L15.3601 9.69986C15.6501 9.40986 15.6501 8.92986 15.3601 8.63986C15.0701 8.34986 14.5901 8.34986 14.3001 8.63986L12.0001 10.9399L9.70011 8.63986C9.41011 8.34986 8.93011 8.34986 8.64011 8.63986C8.35011 8.92986 8.35011 9.40986 8.64011 9.69986L10.9401 11.9999L8.64011 14.2999C8.35011 14.5899 8.35011 15.0699 8.64011 15.3599C8.79011 15.5099 8.98011 15.5799 9.17011 15.5799C9.36011 15.5799 9.55011 15.5099 9.70011 15.3599L12.0001 13.0599L14.3001 15.3599C14.4501 15.5099 14.6401 15.5799 14.8301 15.5799C15.0201 15.5799 15.2101 15.5099 15.3601 15.3599C15.6501 15.0699 15.6501 14.5899 15.3601 14.2999L13.0601 11.9999Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default CloseIcon;
