import { FC, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { FormInput, FormSelect } from 'components/common/Form';
import useAuth from 'store/auth';
import { CustomFieldType, IUserCreateDTO, OptionType } from 'types';
import { TranslationKey } from 'utils/translate';
import useAllCountries from 'store/countries';

interface Props {
  control: Control<Partial<IUserCreateDTO>>;
  fields?: CustomFieldType[];
  permission: CashierPermissionsEnum;
}

const CreateShopFields: FC<Props> = ({ control, permission, fields = [] }) => {
  const [authState] = useAuth();
  const [countriesState] = useAllCountries();

  const currencies = useMemo(() => {
    return (authState?.data?.cashier?.currencyCodes ?? []).map(
      (i) =>
        ({
          id: i,
          name: i,
        }) as OptionType,
    );
  }, [authState?.data?.cashier?.currencyCodes]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormInput required label="Name" name="name" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect required multiple label="Currencies" name="currencyCodes" control={control} options={currencies} />
      </Grid>
      {permission === CashierPermissionsEnum.POS_ADMIN && (
        <>
          <Grid item xs={12} md={6}>
            <FormSelect required label="Country" name="country" control={control} options={countriesState.data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput required label="City" name="city" control={control} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput required label="Address" name="address" control={control} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInput required label="Work hours" name="workHours" control={control} />
          </Grid>
        </>
      )}
      {fields.map((field) => (
        <Grid item xs={12} md={6} key={field.key}>
          <FormInput
            name={field.key}
            required={field.isRequired}
            label={field.name as TranslationKey}
            control={control}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CreateShopFields;
