import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LOGIN, PUT_ACTIVE_PARTNER } from 'api/paths/constants';
import { RootState } from 'store/types';
import { authStorage } from 'store/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { ApiResponseType } from 'types';
import { EMPTY_OBJECT } from 'constant';

import {
  AuthLoginResponseType,
  AuthLoginSchemaType,
  UseAuthStateType,
  UseAuthActionsType,
  IUser,
  AuthState,
} from './types';
import { LOGOUT_ACTION_TYPE } from './actionTypes';

const initialState: UseAuthStateType = {
  isLoading: false,
  branding: null,
  data: authStorage.get(),
};

const login = createAsyncThunk<AuthLoginResponseType, AuthLoginSchemaType>(
  'cashier/login',
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<
        {
          accessToken: string;
          cashier: IUser;
        },
        AuthLoginSchemaType
      >({
        url: LOGIN,
        method: 'POST',
        body,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const changePartner = createAsyncThunk<AuthLoginResponseType, number>(
  'active-partner',
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<
        {
          accessToken: string;
          cashier: IUser;
        },
        AuthLoginSchemaType
      >({
        url: PUT_ACTIVE_PARTNER(body),
        method: 'PUT',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder
      .addCase(login.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled.type, (state, action: PayloadAction<{ data: AuthState }>): void => {
        if (action.payload?.data?.cashier?.id) {
          authStorage.set(action.payload.data);
          state.data = action.payload?.data;
        }
        state.isLoading = false;
      })
      .addCase(login.rejected.type, (state, action: PayloadAction<ApiResponseType<null>>): void => {
        state.data = EMPTY_OBJECT;
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(changePartner.fulfilled.type, (state, action: PayloadAction<{ data: AuthState }>): void => {
        if (action.payload?.data?.cashier?.id) {
          authStorage.set(action.payload.data);
          state.data = action.payload?.data;
        }
      }),
});

const useAuth = (): [UseAuthStateType, UseAuthActionsType] => {
  const state = useAppSelector((store: RootState) => store.auth);
  const dispatch = useAppDispatch();

  const actions = {
    login: async (params: AuthLoginSchemaType): Promise<void> => {
      await dispatch(login(params));
    },
    changePartner: async (params: number): Promise<void> => {
      await dispatch(changePartner(params));
    },
    logout: (): void => {
      authStorage.clear();
      dispatch({ type: LOGOUT_ACTION_TYPE });
    },
  };

  return [state, actions];
};

export { authSlice };

export * from './types';

export default useAuth;
