import { CashierPermissionsEnum } from 'enums/accessTemplates';

import SettingsIcon from 'components/common/icons/bulk/Settings';
import HierarchySquareIcon from 'components/common/icons/bulk/HierarchySquare';
import PresentationChartIcon from 'components/common/icons/bulk/PresentationChart';
import CashiersIcon from 'components/common/icons/bulk/Cashiers';
import ReportsIcon from 'components/common/icons/bulk/Reports';
import PaymentsIcon from 'components/common/icons/bulk/Payments';
import ConfigurationsIcon from 'components/common/icons/bulk/Configurations';
import BetShopIcon from 'components/common/icons/bulk/Betshop';
import PosIcon from 'components/common/icons/bulk/Pos';

export type NavigationRouteType = {
  name: string;
  path?: string;
  icon?: JSX.Element;
  items?: NavigationRouteType[];
  hide?: boolean;
  permissions?: CashierPermissionsEnum[];
};

export const navigationRoutes: NavigationRouteType[] = [
  {
    name: 'System',
    icon: <HierarchySquareIcon />,
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
    items: [
      {
        name: 'Partner',
        path: '/partners',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN],
      },
      {
        name: 'Payment methods',
        path: '/payment-methods',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN],
      },
    ],
  },

  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <PresentationChartIcon />,
  },
  {
    name: 'Users',
    icon: <CashiersIcon />,
    path: '/users/cashiers',
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
  },
  {
    name: 'BetShop',
    icon: <BetShopIcon />,
    path: '/bet-shop',
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
    ],
  },
  {
    name: 'POS',
    icon: <PosIcon />,
    path: '/pos',
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
  },
  {
    name: 'Payments',
    icon: <PaymentsIcon />,
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
      CashierPermissionsEnum.USER,
    ],
    items: [
      {
        name: 'Withdraw',
        path: '/payments/withdraws',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
      },
      {
        name: 'Deposit',
        path: '/payments/deposits',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
      },
      {
        name: 'Vouchers',
        path: '/payments/vouchers',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
      },
    ],
  },
  {
    name: 'Reports',
    icon: <ReportsIcon />,
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
    items: [
      {
        name: 'By Betshop',
        path: '/reports/bet-shop',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
        ],
      },
      {
        name: 'By POS',
        path: '/reports/pos',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
        ],
      },
      {
        name: 'By Cashier',
        path: '/reports/cashier',
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
        ],
      },
    ],
  },
  {
    name: 'Configurations',
    icon: <ConfigurationsIcon />,
    permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
    items: [
      {
        name: 'BetShop',
        path: '/configurations/bet-shop',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        name: 'POS',
        path: '/configurations/pos',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
    ],
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
    items: [
      {
        name: 'Currencies',
        path: '/settings/currencies',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        name: 'Payment methods',
        path: '/settings/payment-methods',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        name: 'Branding',
        path: '/settings/branding',
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      { name: 'Branding', path: '/settings/branding', hide: true },
    ],
  },
];
