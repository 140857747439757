import { CashierPermissionsEnum, PermissionTemplateTypeEnum } from 'enums/accessTemplates';
import { PeriodEnum } from 'enums/dashboard';
import { TransferTypeEnum, PaymentMethodTypeEnum } from 'enums/entity';

export const STATUSES_OPTIONS = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'BLOCK', name: 'Blocked' },
];

export const VERIFICATION_STATUSES_OPTIONS = [
  { id: '1', name: 'Verified' },
  { id: '2', name: 'Not verified' },
  { id: '3', name: 'Awaiting verified' },
];

export const GENDER_OPTIONS = [
  { id: '1', name: 'Male' },
  { id: '2', name: 'Female' },
];

export const PREMIUM_OPTIONS = [
  { id: 'true', name: 'Premium' },
  { id: 'false', name: 'Basic' },
];

export const YES_OR_NO_OPTIONS = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const RATIOS = [
  {
    id: 1,
    name: '16:9',
  },
  {
    id: 2,
    name: '4:3',
  },
];

export const CHANNELS = [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: 'Web',
  },
  {
    id: 2,
    name: 'Mobile',
  },
  {
    id: 3,
    name: 'Tablet',
  },
  {
    id: 4,
    name: 'Backend',
  },
];

export const TRANSFER_TYPE_OPTIONS = [
  {
    id: TransferTypeEnum.DEPOSIT,
    name: 'Deposit',
  },
  {
    id: TransferTypeEnum.WITHDRAW,
    name: 'Withdraw',
  },
  {
    id: TransferTypeEnum.BOTH,
    name: 'Both',
  },
];

export const CUSTOM_TRANSFER_TYPE_OPTIONS = [
  {
    id: TransferTypeEnum.DEPOSIT,
    name: 'Deposit',
  },
  {
    id: TransferTypeEnum.WITHDRAW,
    name: 'Withdraw',
  },
];

export const PAYMENT_METHOD_TYPE_OPTIONS = [
  {
    id: PaymentMethodTypeEnum.MANUAL,
    name: 'Manual',
  },
  {
    id: PaymentMethodTypeEnum.AUTOMATED,
    name: 'Automated',
  },
];

export const ACCESS_TYPE_OPTIONS = [
  {
    id: PermissionTemplateTypeEnum.PUBLIC,
    name: 'Public',
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
  },
  {
    id: PermissionTemplateTypeEnum.PRIVATE,
    name: 'Private',
  },
  {
    id: PermissionTemplateTypeEnum.LAYERED,
    name: 'Layered',
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
  },
];

export const PERMISSIONS_OPTIONS = [
  {
    id: CashierPermissionsEnum.SUPER_ADMIN,
    name: 'Super Admin',
  },
];

export const periodicalOptions = [
  {
    id: PeriodEnum.TODAY,
    name: 'Today',
  },
  {
    id: PeriodEnum.YESTERDAY,
    name: 'Yesterday',
  },
  {
    id: PeriodEnum.WEEK,
    name: 'This week',
  },
  {
    id: PeriodEnum.LAST_WEEK,
    name: 'Last week',
  },
  {
    id: PeriodEnum.MONTH,
    name: 'This month',
  },
  {
    id: PeriodEnum.LAST_MONTH,
    name: 'Last month',
  },
];

export const PAYMENT_TRANSACTIONS_STATUS_OPTIONS = [
  { id: 'IN_REVIEW', name: 'IN_REVIEW' },
  { id: 'FAILED', name: 'FAILED' },
  { id: 'PENDING', name: 'PENDING' },
  { id: 'ACCEPTED', name: 'ACCEPTED' },
  { id: 'CANCELED', name: 'CANCELED' },
  { id: 'COMPLETE', name: 'COMPLETE' },
];

export const TRANSFER_TYPE_OPTION = [
  { id: 'DEPOSIT_COMMISSION', name: 'DEPOSIT_COMMISSION' },
  { id: 'WITHDRAW_COMMISSION', name: 'WITHDRAW_COMMISSION' },
  { id: 'DEPOSIT', name: 'DEPOSIT' },
  { id: 'WITHDRAW', name: 'WITHDRAW' },
  { id: 'BOTH', name: 'BOTH' },
];
