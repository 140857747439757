/* eslint-disable */

import { FC, Suspense, useState } from 'react';
import { Box, Theme, useTheme } from '@mui/material';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { SwitchRouter } from 'pages/utils';
import useScreen from 'hooks/useScreen';
import useAuth from 'store/auth';
import usePartners from 'store/partner';
import useCurrencies from 'store/currencies';
import useMount from 'hooks/useMount';
import HeaderNavigation from 'components/sections/HeaderNavigation';
import SidebarNavigation from 'components/sections/SidebarNavigation';
import ProjectLoading from 'components/sections/ProjectLoading';
import Preloader from 'components/sections/Preloader';
import FooterNavigation from 'components/sections/FooterNavigation';

import routes from './routes';
import useAllCountries from 'store/countries';
import useBranding from 'store/branding';

const sx = (theme: Theme) => ({
  content: {
    [theme.breakpoints.down('md')]: {
      pl: '0px',
    },
    [theme.breakpoints.up('md')]: {
      pl: '300px',
    },
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 65px)',
  },
});

const App: FC = () => {
  const theme = useTheme();
  const { isMobile } = useScreen();
  const [open, setOpen] = useState(!isMobile);
  const [authState] = useAuth();
  const [, currencyActions] = useCurrencies();
  const [, partnerActions] = usePartners();
  const [, countriesActions] = useAllCountries();
  const [branding] = useBranding();

  useMount(() => {
    const permission = authState?.data?.cashier?.permission;

    if (permission === CashierPermissionsEnum.SUPER_ADMIN || permission === CashierPermissionsEnum.PARTNER_ADMIN) {
      currencyActions.getAll();
      currencyActions.getPartner();
    }
    if (permission === CashierPermissionsEnum.SUPER_ADMIN) {
      partnerActions.get();
    }

    countriesActions.get();
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <Preloader>
      <Box minHeight="100vh">
        <SidebarNavigation
          setOpen={setOpen}
          open={isMobile ? open : true}
          logoPath={branding?.settings.logo || '/assets/images/light-logo.svg'}
          existPermission={authState.data?.cashier?.permission as CashierPermissionsEnum}
        />
        <Box sx={sx(theme).content}>
          <HeaderNavigation setSidebarOpen={handleDrawerOpen} />
          <Box
            width="100%"
            height="100%"
            flexGrow={1}
            sx={{
              [theme.breakpoints.down('sm')]: {
                paddingTop: '65px',
                paddingBottom: '65px',
              },
            }}
          >
            <Suspense fallback={<ProjectLoading isFixed={false} />}>
              <SwitchRouter
                routes={routes}
                existPermission={authState?.data?.cashier?.permission as CashierPermissionsEnum}
              />
            </Suspense>
          </Box>
          <FooterNavigation existPermission={authState.data?.cashier?.permission as CashierPermissionsEnum} />
        </Box>
      </Box>
    </Preloader>
  );
};

export default App;
