import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GET_CASHIERS_BRANDING } from 'api/paths/constants';
import { ApiResponseType, BrandingType } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_OBJECT } from 'constant';

type UseBrandingActionsType = {
  get: (url: string) => void;
};

type UseBrandingStateType = {
  settings: BrandingType;
};

const initialState: UseBrandingStateType = {
  settings: {
    id: 0,
    actionColor: '',
    bgColor: '',
    description: '',
    favicon: '',
    fontColor: '',
    loader: '',
    logo: '',
    loginLogo: '',
    title: '',
    socialFacebook: '',
    socialInstagram: '',
    socialTelegram: '',
    socialWhatsapp: '',
    socialX: '',
    socialYoutube: '',
  },
};

const getBranding = createAsyncThunk<ApiResponseType<BrandingType>, string>(
  GET_CASHIERS_BRANDING,
  async (url, { rejectWithValue }) => {
    try {
      const response = await fetcher<BrandingType>({
        method: 'GET',
        url,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder.addCase(getBranding.fulfilled.type, (state, action: PayloadAction<{ data: BrandingType }>): void => {
      state.settings = action.payload?.data;
    }),
});

const useBranding = (): [UseBrandingStateType, UseBrandingActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.branding);
  const dispatch = useAppDispatch();

  const actions = {
    get: async (url: string): Promise<void> => {
      await dispatch(getBranding(url));
    },
  };

  return [state, actions];
};

export { brandingSlice };

export default useBranding;
