import { ChangeEventHandler, useState } from 'react';
import { Box, Button, CircularProgress, Fade, IconButton, Modal, Stack, Typography } from '@mui/material';

import galleries from 'store/galleries';
import useToaster from 'hooks/useToaster';
import TrashIcon from 'components/common/icons/Trash';
import EyeIcon from 'components/common/icons/bold/Eye';
import { TranslationItem, TranslationKey, translate } from 'utils/translate';
import validateFile, { IFileValidatorsType } from 'utils/validateFile';
import GalleryAddIcon from 'components/common/icons/bulk/GalleryAdd';
import AspectRatio, { AspectRatioProps } from 'components/common/AspectRatio';

import CloseIcon from '../icons/bulk/Close';

import sx from './styles';

export interface IUploaderProps {
  value?: string;
  error?: string;
  disabled?: boolean;
  label: TranslationKey;
  helperText?: TranslationItem;
  validators?: IFileValidatorsType;
  ratio?: AspectRatioProps['divide'];
  onChange?: (value: string | null) => void;
}

const Uploader = ({ label, ratio, value, onChange, error, helperText, validators }: IUploaderProps): JSX.Element => {
  const notify = useToaster();
  const [image, setImage] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendToUpload = async (file: File): Promise<void> => {
    const response = await galleries.upload({
      file,
    });

    if (response?.data?.length) {
      onChange?.(response.data?.[0] || null);
    }
  };

  const handleImage = (imgUrl: string): void => {
    setImage(imgUrl);
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const onUpload = async (file: File): Promise<void> => {
    try {
      if (validators) {
        const validation = await validateFile(file, validators);

        if (validation.isValid) {
          await sendToUpload(file);
        } else {
          notify({
            message: validation.error as TranslationKey,
            type: 'error',
          });
        }
      } else {
        await sendToUpload(file);
      }
    } catch (err) {
      notify({
        message: 'Upload failed, please try again',
        type: 'error',
      });
    }
  };

  const handleUpload: ChangeEventHandler<HTMLInputElement> = async (event): Promise<void> => {
    setIsLoading(true);
    const files = event?.target?.files;

    if (files?.length) {
      await Promise.all([...files].map(onUpload));
    }

    setIsLoading(false);
  };

  const handleRemove = (): void => {
    onChange?.(null);
  };

  const imageSrc = value;
  const acceptFileTypes = validators?.type?.join(', ');

  return (
    <>
      <Box sx={sx.root}>
        <Box flex={1} maxWidth={88}>
          <AspectRatio divide={ratio}>
            <Box role="image-cover" {...(imageSrc && { sx: sx.imageCover({ src: imageSrc }) })}>
              {imageSrc ? (
                <Box role="overlay" sx={sx.overlay}>
                  <Stack direction="row" spacing={1}>
                    <IconButton onClick={(): void => handleImage((image || value) as string)}>
                      <EyeIcon />
                    </IconButton>
                    <IconButton onClick={handleRemove}>
                      <TrashIcon />
                    </IconButton>
                  </Stack>
                </Box>
              ) : (
                <Box sx={sx.imageCoverIcon}>
                  <GalleryAddIcon color="primary" />
                </Box>
              )}
            </Box>
          </AspectRatio>
        </Box>
        <Box flex={1} ml={1.5}>
          <Typography mb={0.5} fontSize={14} color="custom.neutrals.scale.600" fontWeight={500}>
            {translate(label)}
          </Typography>
          {helperText && (
            <Typography mb={1} fontSize={14} color="custom.neutrals.scale.400">
              {translate(helperText)}
            </Typography>
          )}
          <Button component="label" variant="contained" color="primary" sx={sx.uploadButton}>
            {translate('Upload')}
            <input hidden value={''} accept={acceptFileTypes} type="file" onChange={handleUpload} />
          </Button>
        </Box>
        {isLoading && (
          <Box display="flex" alignItems="center">
            <CircularProgress size={32} />
          </Box>
        )}
        {!!error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </Box>
      <Modal sx={sx.modal} open={open} onClose={handleClose} closeAfterTransition>
        <>
          <IconButton sx={sx.close} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Fade in={open} timeout={500} style={sx.img}>
            <img src={(image && image) || ''} alt="image" style={{ maxHeight: '90%', maxWidth: '90%' }} />
          </Fade>
        </>
      </Modal>
    </>
  );
};

export default Uploader;
