import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_BET_SHOP, GET_POS_SINGLE } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IShopListDTO } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseShopStateType = {
  data: PaginatedResponse<IShopListDTO>;

  isLoading: boolean;
};

type UseShopActionsType = {
  getSingleBetShop: (id: number) => Promise<{ data: IShopListDTO } | undefined>;
  getSinglePos: (id: number) => Promise<{ data: IShopListDTO } | undefined>;
  getLocalById: (id: number) => IShopListDTO | undefined;
  save: (options: ISaveOptions<IShopListDTO>) => void;
  addShop: (data: IShopListDTO) => void;
};

const initialState: UseShopStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getSingleBetShop = createAsyncThunk<ApiResponseType<IShopListDTO>, number>(
  'shop/getSingleBetShop',
  async (id: number) => {
    return fetcher({
      url: GET_BET_SHOP(id),
    });
  },
);

const getSinglePos = createAsyncThunk<ApiResponseType<IShopListDTO>, number>(
  'shop/getSinglePos',
  async (id: number) => {
    return fetcher({
      url: GET_POS_SINGLE(id),
    });
  },
);

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addShop(state, action: PayloadAction<IShopListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSingleBetShop.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getSingleBetShop.fulfilled.type, (state, action: PayloadAction<{ data: IShopListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getSingleBetShop.rejected.type, (state): void => {
        state.isLoading = false;
      })
      .addCase(getSinglePos.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getSinglePos.fulfilled.type, (state, action: PayloadAction<{ data: IShopListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getSinglePos.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const useShop = (): [UseShopStateType, UseShopActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.shop);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      getSingleBetShop: async (id: number): Promise<{ data: IShopListDTO } | undefined> => {
        const shop = await dispatch(getSingleBetShop(id));
        return shop.payload as Promise<{ data: IShopListDTO } | undefined>;
      },
      getSinglePos: async (id: number): Promise<{ data: IShopListDTO } | undefined> => {
        const shop = await dispatch(getSinglePos(id));
        return shop.payload as Promise<{ data: IShopListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((shop) => shop.id === id),
      save: (options: ISaveOptions<IShopListDTO>): void => {
        dispatch(shopSlice.actions.save(options));
      },
      addShop: (model: IShopListDTO): void => {
        dispatch(shopSlice.actions.addShop(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { shopSlice };

export default useShop;
