import { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Check from '@mui/icons-material/Check';

import CopyIcon from '../icons/bulk/Copy';

const Copy: FC<{ value: string }> = ({ value }) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const copyName = (name: string): void => {
    const tempInput = document.createElement('input');
    tempInput.value = name;
    document.body.appendChild(tempInput);
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(tempInput.value);
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    setIsCopied(name);
    setTimeout(() => {
      setIsCopied(null);
    }, 2000);
  };

  return (
    <IconButton sx={{ padding: '0px' }} onClick={(): void => copyName(value)}>
      {isCopied ? <Check /> : <CopyIcon />}
    </IconButton>
  );
};

export default Copy;
