import { CashierPermissionsEnum } from 'enums/accessTemplates';
import { CashierShopTypeEnum, EntityEnum } from 'enums/entity';

import { TranslationKey } from 'utils/translate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_ARRAY = Object.freeze([]) as unknown as any[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EMPTY_OBJECT = Object.freeze({}) as Record<string, any>;

export const ACTION_BUTTON_ID = 'action-button';
export const START_ADORMENT_ID = 'data-grid-start-adorment';
export const TAB_ROUTER_LAYOUT_ACTION_BUTTON_ID = 'tab-router-layout-action-button';

export const ENTITY_TYPES_INFO = {
  [EntityEnum.SHOP]: {
    label: 'Shop' as TranslationKey,
    value: 'shop',
    route: 'shop',
  },
  [EntityEnum.USER]: {
    label: 'User' as TranslationKey,
    value: 'user',
    route: 'user',
  },
};

export const SHOP_PAGE_TYPE = {
  '/bet-shop': CashierShopTypeEnum.BET_SHOP,
  '/pos': CashierShopTypeEnum.POS,
  '/payment-properties': CashierShopTypeEnum.PAYMENT,
};

export const USER_PAGE_TYPE = {
  '/partner-admin': CashierPermissionsEnum.PARTNER_ADMIN,
  '/bet-shop-admin': CashierPermissionsEnum.BET_SHOP_ADMIN,
  '/pos-admin': CashierPermissionsEnum.POS_ADMIN,
  '/cashiers': CashierPermissionsEnum.USER,
};

export const SHOP_PAGE_Title = {
  [CashierShopTypeEnum.BET_SHOP]: 'Betshop',
  [CashierShopTypeEnum.POS]: 'POS',
  [CashierShopTypeEnum.PAYMENT]: 'Payment Method',
};

export const PAYMENT_PROVIDER_SYSTEM_TITLE = 'System';
export const PAYMENT_METHOD_SYSTEM_TITLE = 'System';
export const PAYMENT_METHOD_SYSTEM_ID = 0;
export const PAYMENT_PROVIDER_SYSTEM_ID = '-1';
