import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import LinkBase from '@mui/material/Link';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import fetcher from 'utils/fetcher';
import { GET_SEARCH } from 'api/paths/constants';
import { SearchResponseType } from 'types';
import { translate } from 'utils/translate';

import SearchField from './SearchField';

const SearchBox: FC = () => {
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState<{ username: string; id: string }[]>([]);
  const handleSearch = async (value: string): Promise<void> => {
    setSearchTerm(value);
    if (value) {
      try {
        const response = await fetcher<SearchResponseType>({
          method: 'GET',
          url: GET_SEARCH,
          params: {
            search: value,
          },
        });

        setSearchData([...(response?.data || [])]);
      } catch {
        setSearchData([]);
      }
    }
  };

  const handleClickAway = (): void => {
    setSearchData([]);
    setSearchTerm('');
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
          },
        }}
      >
        <SearchField onChange={handleSearch} defaultValue={searchTerm} />
        {searchData?.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: '100%',
              width: '100%',
              borderWidth: 1,
              borderRadius: 0.5,
              borderStyle: 'solid',
              borderColor: 'custom.neutrals.scale.100',
              bgcolor: 'custom.common.white',
              overflowY: 'auto',
              maxHeight: '300px',
            }}
          >
            {searchData.map((item) => (
              <LinkBase
                key={item.id}
                component={Link}
                to={`/users/${item.id}/main`}
                underline="none"
                sx={{
                  display: 'block',
                  p: 1,
                  bgcolor: 'custom.neutrals.scale.25',
                  color: 'custom.neutrals.scale.600',
                  '&:hover': { bgcolor: 'custom.primary.scale.50' },
                }}
              >
                {translate('User')}: {item.username}
              </LinkBase>
            ))}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchBox;
