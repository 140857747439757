export enum PermissionTemplateTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  LAYERED = 'LAYERED',
}

export enum CashierPermissionsEnum {
  SUPER_ADMIN = 'cashier_super_admin',
  PARTNER_ADMIN = 'cashier_partner_admin',
  BET_SHOP_ADMIN = 'cashier_bet_shop_admin',
  POS_ADMIN = 'cashier_pos_admin',
  USER = 'cashier_user',
}
