import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { GET_USER_SINGLE } from 'api/paths/constants';
import { ApiResponseType, ISaveOptions, PaginatedResponse, IUserListDTO } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseUserStateType = {
  data: PaginatedResponse<IUserListDTO>;
  isLoading: boolean;
};

type UseUserActionsType = {
  get: (id: number) => Promise<{ data: IUserListDTO } | undefined>;
  getLocalById: (id: number) => IUserListDTO | undefined;
  save: (options: ISaveOptions<IUserListDTO>) => void;
  addUser: (data: IUserListDTO) => void;
};

const initialState: UseUserStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getUser = createAsyncThunk<ApiResponseType<IUserListDTO>, number>('user/get', async (id: number) => {
  return fetcher({
    url: GET_USER_SINGLE(id),
  });
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addUser(state, action: PayloadAction<IUserListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUser.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled.type, (state, action: PayloadAction<{ data: IUserListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getUser.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const useShop = (): [UseUserStateType, UseUserActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.user);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: number): Promise<{ data: IUserListDTO } | undefined> => {
        const cashier = await dispatch(getUser(id));
        return cashier.payload as Promise<{ data: IUserListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((user) => user.id === id),
      save: (options: ISaveOptions<IUserListDTO>): void => {
        dispatch(userSlice.actions.save(options));
      },
      addUser: (model: IUserListDTO): void => {
        dispatch(userSlice.actions.addUser(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { userSlice };

export default useShop;
