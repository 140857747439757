import fetcher from 'utils/fetcher';
import { ApiResponseType } from 'types';

export type GalleryType = string;

export type UploadGalleryType = {
  file: File;
};

const uploadGallery = async ({ file }: UploadGalleryType): Promise<ApiResponseType<GalleryType[]>> => {
  const formData = new FormData();

  formData.append('image', file);

  return fetcher<GalleryType[], FormData>({
    url: 'cashiers/upload',
    method: 'POST',
    body: formData,
  });
};
const galleries = {
  upload: uploadGallery,
};

export default galleries;
