import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  GET_CASHIERS_SYSTEM_CURRENCIES,
  GET_CASHIERS_PARTNER_CURRENCIES,
  POST_CASHIERS_CURRENCY,
  PUT_CASHIERS_CURRENCY,
} from 'api/paths/constants';
import { ApiResponseType, ICurrencyCreateDTO, IPublicCurrency, ICurrency } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constant';

type UseAllCurrenciesStateType = {
  allCurrencies: IPublicCurrency[];
  partnerCurrencies: ICurrency[];
  isLoading: boolean;
};

type UseAllCurrenciesActionsType = {
  getPartner: () => Promise<void>;
  getAll: () => void;
  addPartnerCurrency: (data: ICurrencyCreateDTO) => Promise<void>;
  updatePartnerCurrency: (data: Partial<ICurrency>) => Promise<void>;
};

const initialState: UseAllCurrenciesStateType = {
  isLoading: false,
  allCurrencies: EMPTY_ARRAY,
  partnerCurrencies: EMPTY_ARRAY,
};

const getPartnerCurrencies = createAsyncThunk<ApiResponseType<IPublicCurrency>>(
  'getPartnerCurrencies/get',
  async () => {
    return fetcher({
      url: GET_CASHIERS_PARTNER_CURRENCIES,
    });
  },
);

const getAllCurrencies = createAsyncThunk<ApiResponseType<IPublicCurrency>>('getAllCurrencies/get', async () => {
  return fetcher({
    url: GET_CASHIERS_SYSTEM_CURRENCIES,
  });
});

const addPartnerCurrency = createAsyncThunk<ApiResponseType<IPublicCurrency>, ICurrencyCreateDTO>(
  'addPartnerCurrency/updateField',
  async (body: ICurrencyCreateDTO) => {
    return fetcher({
      url: POST_CASHIERS_CURRENCY,
      method: 'POST',
      body,
    });
  },
);

const updatePartnerCurrency = createAsyncThunk<ApiResponseType<ICurrency>, Partial<ICurrency>>(
  'updatePartnerCurrency/updateField',
  async (body: Partial<ICurrency>) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_CASHIERS_CURRENCY(id as number),
      method: 'PUT',
      body: rest,
    });
  },
);

const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder
      .addCase(getAllCurrencies.fulfilled.type, (state, action: PayloadAction<{ data: IPublicCurrency[] }>): void => {
        state.isLoading = false;
        state.allCurrencies = action.payload?.data;
      })
      .addCase(getPartnerCurrencies.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency[] }>): void => {
        state.partnerCurrencies = action.payload?.data;
      })
      .addCase(addPartnerCurrency.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency }>): void => {
        state.partnerCurrencies = [action.payload.data, ...state.partnerCurrencies];
      })
      .addCase(updatePartnerCurrency.fulfilled.type, (state, action: PayloadAction<{ data: ICurrency }>): void => {
        const data = action.payload.data;
        state.partnerCurrencies = state.partnerCurrencies.map((i: ICurrency) => (i.id === data.id ? { ...data } : i));
      }),
});

const useCurrencies = (): [UseAllCurrenciesStateType, UseAllCurrenciesActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.currencies);
  const dispatch = useAppDispatch();

  const actions = {
    getPartner: async (): Promise<void> => {
      await dispatch(getPartnerCurrencies());
    },
    getAll: (): void => {
      dispatch(getAllCurrencies());
    },
    addPartnerCurrency: async (data: ICurrencyCreateDTO): Promise<void> => {
      await dispatch(addPartnerCurrency(data));
    },
    updatePartnerCurrency: async (data: Partial<ICurrency>): Promise<void> => {
      await dispatch(updatePartnerCurrency(data));
    },
  };

  return [state, actions];
};

export { currenciesSlice };

export default useCurrencies;
