import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const PosIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M22 6.00008V8.42008C22 10.0001 21 11.0001 19.42 11.0001H16V4.01008C16 2.90008 16.91 1.99008 18.02 2.00008C19.11 2.01008 20.11 2.45008 20.83 3.17008C21.55 3.90008 22 4.90008 22 6.00008Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M2 7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7H6C3 2 2 3.79 2 6V7Z"
      fill="inherit"
    />
    <path
      d="M12 12.26H9C8.59 12.26 8.25 12.6 8.25 13.01C8.25 13.42 8.59 13.76 9 13.76H12C12.41 13.76 12.75 13.42 12.75 13.01C12.75 12.6 12.41 12.26 12 12.26Z"
      fill="inherit"
    />
    <path
      d="M9 9.76001H12C12.41 9.76001 12.75 9.42001 12.75 9.01001C12.75 8.60001 12.41 8.26001 12 8.26001H9C8.59 8.26001 8.25 8.60001 8.25 9.01001C8.25 9.42001 8.59 9.76001 9 9.76001Z"
      fill="inherit"
    />
    <path
      d="M5.96997 8.01001C5.40997 8.01001 4.96997 8.46001 4.96997 9.01001C4.96997 9.56001 5.41997 10.01 5.96997 10.01C6.51997 10.01 6.96997 9.56001 6.96997 9.01001C6.96997 8.46001 6.51997 8.01001 5.96997 8.01001Z"
      fill="inherit"
    />
    <path
      d="M5.96997 12.01C5.40997 12.01 4.96997 12.46 4.96997 13.01C4.96997 13.56 5.41997 14.01 5.96997 14.01C6.51997 14.01 6.96997 13.56 6.96997 13.01C6.96997 12.46 6.51997 12.01 5.96997 12.01Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default PosIcon;
