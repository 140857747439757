import { ApiResponseType, IShopListDTO, PaginatedResponse } from 'types';
import fetcher from 'utils/fetcher';

import { GET_BET_SHOPS } from '../paths/constants';

export const getBetShops = ({
  page,
  size,
}: {
  page: string;
  size: string;
}): Promise<ApiResponseType<PaginatedResponse<IShopListDTO>>> =>
  fetcher<PaginatedResponse<IShopListDTO>>({
    method: 'GET',
    url: GET_BET_SHOPS,
    params: { page, size },
  });
