import * as yup from 'yup';
import type { Schema, ObjectSchema } from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { TransferTypeEnum, PaymentMethodTypeEnum } from 'enums/entity';

export const loginSchema = yup
  .object()
  .shape({
    email: yup.string().trim().required(),
    password: yup.string().trim().label('Password').required(),
  })
  .required();

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().trim().required(),
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const changePasswordForUserSchema = yup.object().shape({
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const transferMoneySchemaShape = {
  betShop: yup.string().trim().required(),
  walletId: yup.string().required(),
  type: yup.mixed<TransferTypeEnum>().oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW]).required(),
  amount: yup.number().positive().required(),
};

export const transferBetShopMoneySchema = yup
  .object()
  .shape({ ...transferMoneySchemaShape })
  .required();

export const transferPosMoneySchema = yup
  .object()
  .shape({
    ...transferMoneySchemaShape,
    pos: yup.string().trim().required(),
  })
  .required();

export const transferBetShopSingleMoneySchema = yup
  .object()
  .shape({ ...transferMoneySchemaShape, betShop: yup.string().trim().required() })
  .required();

export const transferPosSingleMoneySchema = yup
  .object()
  .shape({
    ...transferMoneySchemaShape,
    betShop: yup.string().trim().required(),
    pos: yup.string().trim().required(),
  })
  .required();

export const transferPlayerMoneySchema = yup
  .object()
  .shape({
    type: yup.mixed<TransferTypeEnum>().oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW]).required(),
    playerId: yup.string().required(),
    amount: yup
      .number()
      .positive()
      .when('type', ([type], schema) => (type === TransferTypeEnum.DEPOSIT ? schema.required() : schema.nullable())),
    code: yup
      .string()
      .when('type', ([type], schema) => (type === TransferTypeEnum.WITHDRAW ? schema.required() : schema.nullable())),
  })
  .required();

export const createUserSchemaShape = {
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('password')]),
  firstName: yup.string().trim(),
  lastName: yup.string().trim(),
  email: yup.string().trim().email().required(),
  phoneNumber: yup.string().test('invalid', (value) => (value ? matchIsValidTel(value) : true)),
};

export const createPartnerAdminSchema = yup
  .object()
  .shape({
    ...createUserSchemaShape,
  })
  .required();

export const createBetShopAdminSchema = yup
  .object()
  .shape({
    ...createUserSchemaShape,
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
  })
  .required();

export const createPosAdminSchema = yup
  .object()
  .shape({
    ...createUserSchemaShape,
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
    shopId: yup.string().trim().required(),
  })
  .required();

export const createCashierSchema = yup
  .object()
  .shape({
    ...createUserSchemaShape,
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
    shopId: yup.string().trim().required(),
  })
  .required();

export const createShopBetShopSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
  })
  .required();

export const createShopPosSchema = yup
  .object()
  .shape({
    name: yup.string().required().label('Name'),
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required()
      .label('Currency'),
    country: yup.string().required().label('Country'),
    city: yup.string().required().label('City'),
    address: yup.string().required().label('Address'),
    workHours: yup.string().required().label('Work hours'),
  })
  .required();

export const createPartnerSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    domain: yup.string().required(),
  })
  .required();

export const updatePartnerGeneralSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    domain: yup.string(),
    status: yup.boolean().required(),
    contactEmail: yup.string().trim().email(),
  })
  .required();

export const updatePartnerPlatformSchema = yup
  .object()
  .shape({
    providerId: yup.number().required(),
    meta: yup.string(),
  })
  .required();

export const updateUserGeneralSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    username: yup.string().required().label('Username'),
    phoneNumber: yup.string().optional().label('Phone Number'),
    email: yup.string().required().label('Email'),
    createdAt: yup.string().required().label('Reg. Date'),
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
    status: yup.boolean().required().label('Status'),
    depositCommissionPercent: yup.number().required().label('Deposit Commission Percent'),
    withdrawCommissionPercent: yup.number().required().label('Withdraw Commission Percent'),
  })
  .required();

export const updatePartnerUserGeneralSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    username: yup.string().required().label('Username'),
    phoneNumber: yup.string().optional().label('Phone Number'),
    email: yup.string().required().label('Email'),
    createdAt: yup.string().required().label('Reg. Date'),
    status: yup.boolean().required().label('Status'),
    depositCommissionPercent: yup.number().required().label('Deposit Commission Percent'),
    withdrawCommissionPercent: yup.number().required().label('Withdraw Commission Percent'),
  })
  .required();

// TODO: VALIDATION_SCHEMA_TRANSLATION
// FIXME: add translation staff
export const updateShopGeneralSchema = yup
  .object()
  .shape({
    name: yup.string().optional().label('Name'),
    createdAt: yup.string().label('Reg. Date'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

export const updatePosShopGeneralSchema = yup
  .object()
  .shape({
    name: yup.string().optional().label('Name'),
    country: yup.string().required().label('Country'),
    city: yup.string().required().label('City'),
    address: yup.string().required().label('Address'),
    workHours: yup.string().required().label('Work hours'),
    createdAt: yup.string().label('Reg. Date'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

export const createCurrencySchema = yup
  .object()
  .shape({
    currencyCode: yup.string().trim().label('Code').required(),
  })
  .required();

export const transferAmountSchema = yup
  .object()
  .shape({
    amount: yup.number().positive().label('Amount').required(),
  })
  .required();

export const createCustomFieldSchemaShape = {
  key: yup
    .string()
    .label('Key')
    .required()
    .test('invalid', (value) => (value ? /^([a-zA-Z]+)$/.test(value) : false)),
  name: yup.string().label('Name').required(),
  isRequired: yup.boolean().label('Required').required(),
};

export const createCustomFieldSchema = yup
  .object()
  .shape({
    ...createCustomFieldSchemaShape,
  })
  .required();

export const createCustomFieldPaymentSchema = yup
  .object()
  .shape({
    ...createCustomFieldSchemaShape,
    type: yup
      .mixed<TransferTypeEnum>()
      .oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW, TransferTypeEnum.BOTH])
      .required(),
  })
  .required();

export const createYupSchema = (
  fields: { key: string; isRequired: boolean }[],
  existSchema: ObjectSchema<Schema>,
): Schema => {
  const generatedSchema = fields.reduce((schema, field) => {
    const validation = yup
      .string()
      .trim()
      .label(field.key)
      .test(field.key, `${field.key} is required`, function (value) {
        if (field.isRequired) {
          return !!value;
        }
        return true;
      });

    return { ...schema, [field.key]: validation };
  }, {});

  return (existSchema ?? yup.object()).shape(generatedSchema).required();
};

export const createSystemPaymentMethodSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    logo: yup.string().nullable(),
    currencyCode: yup.string().trim().label('Code').required(),
    type: yup
      .mixed<PaymentMethodTypeEnum>()
      .oneOf([PaymentMethodTypeEnum.MANUAL, PaymentMethodTypeEnum.AUTOMATED])
      .required(),
    operationType: yup
      .mixed<TransferTypeEnum>()
      .oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW, TransferTypeEnum.BOTH])
      .required(),
  })
  .required();

export const addPartnerPaymentMethodSchema = yup
  .object()
  .shape({
    systemMethodId: yup.string().trim().required(),
  })
  .required();

export const createCredentialSchema = yup
  .object()
  .shape({
    account: yup.string().trim().label('Account').required(),
    name: yup.string().trim().label('Account Name').nullable(),
    image: yup.string().trim().label('image').nullable(),
    meta: yup.string().required(),
  })
  .required();

export const confirmWithdrawSchema = yup
  .object()
  .shape({
    confirmationCode: yup.string().trim().label('Confirmation Code'),
    confirmationImage: yup.string().nullable(),
  })
  .required();

export const createVoucherSchema = yup
  .object()
  .shape({
    amount: yup.string().label('Amount').required(),
    currencyCode: yup.string().required().label('Currency'),
  })
  .required();
