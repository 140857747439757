import { CashierShopTypeEnum } from 'enums/entity';

import {
  ActivityCardsValueType,
  ApiResponseType,
  IPosReport,
  IShopListDTO,
  PaginatedResponse,
  WalletType,
} from 'types';
import fetcher from 'utils/fetcher';
import {
  GET_BET_SHOP_DASHBOARD_CARDS,
  GET_BET_SHOPS_POSES,
  GET_DAILY_REPORTS,
  GET_POS,
  GET_POS_DASHBOARD_CARDS,
  GET_POS_TRANSFER,
  GET_SHOP_WALLET,
  PUT_BET_SHOP,
  PUT_POS,
} from 'api/paths/constants';

export const updateShop = (id: number, data: Partial<IShopListDTO>): Promise<ApiResponseType<IShopListDTO>> =>
  fetcher<IShopListDTO, Partial<IShopListDTO>>({
    method: 'PUT',
    url: data.type === CashierShopTypeEnum.BET_SHOP ? PUT_BET_SHOP(id) : PUT_POS(id),
    body: data,
  });

export const getShopWallets = (shopId: number | string): Promise<ApiResponseType<WalletType[]>> =>
  fetcher<WalletType[]>({
    method: 'GET',
    url: GET_SHOP_WALLET(shopId),
  });

export const getBetShopCards = (
  shopId: string,
  code: string,
): Promise<ApiResponseType<Partial<ActivityCardsValueType>>> =>
  fetcher<Partial<ActivityCardsValueType>>({
    method: 'GET',
    url: GET_BET_SHOP_DASHBOARD_CARDS(shopId, code),
  });

export const getPosCards = (shopId: string, code: string): Promise<ApiResponseType<Partial<ActivityCardsValueType>>> =>
  fetcher<Partial<ActivityCardsValueType>>({
    method: 'GET',
    url: GET_POS_DASHBOARD_CARDS(shopId, code),
  });

export const getPos = ({
  page,
  size,
}: {
  page: string;
  size: string;
}): Promise<ApiResponseType<PaginatedResponse<IShopListDTO>>> =>
  fetcher<PaginatedResponse<IShopListDTO>>({
    method: 'GET',
    url: GET_POS,
    params: { page, size },
  });

export const getPosTransfers = (params: {
  page: string;
  size: string;
  betShopId?: string;
}): Promise<ApiResponseType<PaginatedResponse<IShopListDTO>>> =>
  fetcher<PaginatedResponse<IShopListDTO>>({
    method: 'GET',
    url: GET_POS_TRANSFER,
    params,
  });

export const getShopPOSes = (shopId: number): Promise<ApiResponseType<PaginatedResponse<IShopListDTO>>> =>
  fetcher<PaginatedResponse<IShopListDTO>>({
    method: 'GET',
    url: GET_BET_SHOPS_POSES(shopId),
  });

export const getDailyReports = (shopId: string, from: string, to: string): Promise<ApiResponseType<IPosReport[]>> =>
  fetcher<IPosReport[]>({
    method: 'GET',
    url: GET_DAILY_REPORTS(shopId),
    params: { from, to },
  });
