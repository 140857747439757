import { FC, MouseEventHandler, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useAuth from 'store/auth';
import { translate } from 'utils/translate';

import ChangePasswordModal from '../MainModals/ChangePasswordModal';

const WebsitesMenu: FC = () => {
  const [authState, authAction] = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOpenChangePassword = (): void => {
    handleClose();
    setOpenChangePassword(true);
  };

  return (
    <>
      <Avatar onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {authState?.data?.cashier?.username?.charAt(0)?.toUpperCase()}
      </Avatar>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { elevation: 4, sx: { width: 280 } } }}
      >
        <MenuItem onClick={(): void => navigate(`/users/${authState?.data?.cashier?.id}/dashboard`)}>
          {translate('Profile')}
        </MenuItem>
        <MenuItem onClick={handleOpenChangePassword}>{translate('Change Password')}</MenuItem>
        <MenuItem onClick={authAction.logout}>{translate('Logout')}</MenuItem>
      </Menu>
      {openChangePassword && <ChangePasswordModal onClose={(): void => setOpenChangePassword(false)} />}
    </>
  );
};

export default WebsitesMenu;
