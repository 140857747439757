import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  GET_SYSTEM_PAYMENT_METHODS,
  POST_SYSTEM_PAYMENT_METHOD,
  //   GET_SINGLE_SYSTEM_PAYMENT_METHODS,
  PUT_SYSTEM_PAYMENT_METHODS,
  DELETE_SYSTEM_PAYMENT_METHODS,
  GET_PARTNER_PAYMENT_METHODS,
  GET_PARTNER_ENABLED_PAYMENT_METHODS,
  POST_PARTNER_PAYMENT_METHOD,
  GET_PARTNER_PAYMENT_METHOD_BY_ID,
  PUT_PARTNER_PAYMENT_METHODS,
  DELETE_PARTNER_PAYMENT_METHODS,
  GET_POS_PARTNER_PAYMENT_METHODS,
} from 'api/paths/constants';
import {
  ApiResponseType,
  IPaymentMethod,
  IPartnerPaymentMethod,
  ISaveOptions,
  PaginatedResponse,
  IPagination,
} from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY } from 'constant';

type UsePaymentMethodStateType = {
  systemPaymentMethods: { data: IPaymentMethod[]; count: number };
  partnerPaymentMethods: { data: IPartnerPaymentMethod[]; count: number };
  isLoading: boolean;
};

type UsePaymentMethodActionsType = {
  getSystemPaymentMethods: (params: { page: string; size: string }) => Promise<void>;
  saveSystemPaymentMethods: (options: ISaveOptions<IPaymentMethod>) => void;
  createSystemPaymentMethod: (data: Omit<IPaymentMethod, 'id'>) => Promise<void>;
  updateSystemPaymentMethod: (data: IPaymentMethod) => Promise<void>;
  deleteSystemPaymentMethod: (id: number) => Promise<void>;
  getPartnerPaymentMethods: () => Promise<{ data: { data: IPartnerPaymentMethod; count: number } } | undefined>;
  getPosPaymentMethods: (id: number) => Promise<{ data: { data: IPartnerPaymentMethod; count: number } } | undefined>;
  getPartnerEnabledPaymentMethods: () => Promise<{ data: { data: IPartnerPaymentMethod; count: number } } | undefined>;
  getPartnerPaymentMethodById: (id: number) => Promise<{ data: IPartnerPaymentMethod } | undefined>;
  getLocalPartnerPaymentMethodById: (id: number) => IPartnerPaymentMethod | undefined;
  savePartnerPaymentMethods: (options: ISaveOptions<IPartnerPaymentMethod>) => void;
  createPartnerPaymentMethod: (data: Partial<IPartnerPaymentMethod>) => Promise<void>;
  updatePartnerPaymentMethod: (data: Partial<IPartnerPaymentMethod>) => Promise<void>;
  deletePartnerPaymentMethod: (id: number) => Promise<void>;
};

const initialState: UsePaymentMethodStateType = {
  systemPaymentMethods: {
    data: EMPTY_ARRAY,
    count: 0,
  },
  partnerPaymentMethods: {
    data: EMPTY_ARRAY,
    count: 0,
  },
  isLoading: false,
};

const getSystemPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethod>, IPagination>(
  'cashiers/getSystemPaymentMethods',
  async (params: { page: string; size: string }) => {
    return fetcher({
      params,
      url: GET_SYSTEM_PAYMENT_METHODS,
    });
  },
);

const createSystemPaymentMethod = createAsyncThunk<ApiResponseType<IPaymentMethod>, Omit<IPaymentMethod, 'id'>>(
  'cashiers/createSystemPaymentMethod',
  async (body: Omit<IPaymentMethod, 'id'>) => {
    return fetcher({
      url: POST_SYSTEM_PAYMENT_METHOD,
      method: 'POST',
      body,
    });
  },
);

const updateSystemPaymentMethod = createAsyncThunk<ApiResponseType<IPaymentMethod>, IPaymentMethod>(
  'cashiers/updateSystemPaymentMethod',
  async (body: IPaymentMethod) => {
    const { id, ...rest } = body;
    return fetcher({
      url: PUT_SYSTEM_PAYMENT_METHODS(id),
      method: 'PUT',
      body: rest,
    });
  },
);

const deleteSystemPaymentMethod = createAsyncThunk<ApiResponseType<IPaymentMethod>, number>(
  'cashiers/deleteSystemPaymentMethod',
  async (id: number) => {
    return fetcher({
      url: DELETE_SYSTEM_PAYMENT_METHODS(id),
      method: 'DELETE',
    });
  },
);

const getPartnerPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethod>>(
  'cashiers/getPartnerPaymentMethods',
  async () => {
    return fetcher({
      url: GET_PARTNER_PAYMENT_METHODS,
    });
  },
);

const getPosPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethod>, number>(
  'cashiers/getPosPaymentMethods',
  async (shopId: number) => {
    return fetcher({
      url: GET_POS_PARTNER_PAYMENT_METHODS(shopId),
    });
  },
);

const getPartnerPaymentMethodById = createAsyncThunk<ApiResponseType<IPartnerPaymentMethod>, number>(
  'cashiers/getPartnerPaymentMethodById',
  async (id: number) => {
    return fetcher({
      url: GET_PARTNER_PAYMENT_METHOD_BY_ID(id),
    });
  },
);

const getPartnerEnabledPaymentMethods = createAsyncThunk<ApiResponseType<IPaymentMethod>>(
  'cashiers/getPartnerEnabledPaymentMethods',
  async () => {
    return fetcher({
      url: GET_PARTNER_ENABLED_PAYMENT_METHODS,
    });
  },
);

const createPartnerPaymentMethod = createAsyncThunk<
  ApiResponseType<IPartnerPaymentMethod>,
  Partial<IPartnerPaymentMethod>
>('cashiers/createPartnerPaymentMethod', async (body: Partial<IPartnerPaymentMethod>) => {
  const { partnerId, ...rest } = body;
  return fetcher({
    url: POST_PARTNER_PAYMENT_METHOD(partnerId as number),
    method: 'POST',
    body: rest,
  });
});

const updatePartnerPaymentMethod = createAsyncThunk<
  ApiResponseType<IPartnerPaymentMethod>,
  Partial<IPartnerPaymentMethod>
>('cashiers/updatePartnerPaymentMethod', async (body: Partial<IPartnerPaymentMethod>) => {
  const { id, ...rest } = body;
  return fetcher({
    url: PUT_PARTNER_PAYMENT_METHODS(id as number),
    method: 'PUT',
    body: rest,
  });
});

const deletePartnerPaymentMethod = createAsyncThunk<ApiResponseType<IPartnerPaymentMethod>, number>(
  'cashiers/deletePartnerPaymentMethod',
  async (id: number) => {
    return fetcher({
      url: DELETE_PARTNER_PAYMENT_METHODS(id),
      method: 'DELETE',
    });
  },
);

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    saveSystemPaymentMethods: (state, action) => {
      state.systemPaymentMethods.data = action.payload.data;
      state.systemPaymentMethods.count = action.payload.totalCount;
    },
    savePartnerPaymentMethods: (state, action) => {
      state.partnerPaymentMethods.data = action.payload.data;
      state.partnerPaymentMethods.count = action.payload.totalCount;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        getSystemPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPaymentMethod>; total: number }>): void => {
          state.isLoading = false;
          state.systemPaymentMethods.data = action.payload?.data.data;
          state.systemPaymentMethods.count = action.payload.total || 0;
        },
      )
      .addCase(
        createSystemPaymentMethod.fulfilled.type,
        (state, action: PayloadAction<{ data: IPaymentMethod }>): void => {
          state.systemPaymentMethods.data = [action.payload?.data, ...state.systemPaymentMethods.data];
          state.systemPaymentMethods.count += 1;
        },
      )
      .addCase(
        updateSystemPaymentMethod.fulfilled.type,
        (state, action: PayloadAction<{ data: IPaymentMethod }>): void => {
          state.systemPaymentMethods.data = state.systemPaymentMethods.data.map((i: IPaymentMethod) =>
            i.id === action.payload?.data?.id ? { ...action.payload.data } : i,
          );
        },
      )
      .addCase(
        getPartnerPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: PaginatedResponse<IPartnerPaymentMethod>; total: number }>): void => {
          state.isLoading = false;
          state.partnerPaymentMethods.data = action.payload?.data.data;
          state.partnerPaymentMethods.count = action.payload.total || 0;
        },
      )
      .addCase(
        getPosPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: IPartnerPaymentMethod[]; total: number }>): void => {
          state.isLoading = false;
          state.partnerPaymentMethods.data = action.payload?.data;
          state.partnerPaymentMethods.count = action.payload.total || 0;
        },
      )
      .addCase(
        getPartnerPaymentMethodById.fulfilled.type,
        (state, action: PayloadAction<{ data: IPartnerPaymentMethod; total: number }>): void => {
          state.isLoading = false;
          state.partnerPaymentMethods.data = [...state.partnerPaymentMethods.data, action.payload?.data];
          state.partnerPaymentMethods.count = state.partnerPaymentMethods.count + 1;
        },
      )
      .addCase(
        getPartnerEnabledPaymentMethods.fulfilled.type,
        (state, action: PayloadAction<{ data: IPartnerPaymentMethod[]; total: number }>): void => {
          state.isLoading = false;
          state.partnerPaymentMethods.data = action.payload?.data;
          state.partnerPaymentMethods.count = action.payload.total || 0;
        },
      )
      .addCase(
        createPartnerPaymentMethod.fulfilled.type,
        (state, action: PayloadAction<{ data: IPartnerPaymentMethod }>): void => {
          state.partnerPaymentMethods.data = [action.payload?.data, ...state.partnerPaymentMethods.data];
          state.partnerPaymentMethods.count += 1;
        },
      )
      .addCase(
        updatePartnerPaymentMethod.fulfilled.type,
        (state, action: PayloadAction<{ data: IPartnerPaymentMethod }>): void => {
          state.partnerPaymentMethods.data = state.partnerPaymentMethods.data.map((i: IPartnerPaymentMethod) =>
            i.id === action.payload?.data?.id ? { ...action.payload.data, systemMethod: i.systemMethod } : i,
          );
        },
      ),
});

const usePaymentMethod = (): [UsePaymentMethodStateType, UsePaymentMethodActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.paymentMethod);
  const dispatch = useAppDispatch();

  const actions = {
    saveSystemPaymentMethods: (options: ISaveOptions<IPaymentMethod>): void => {
      dispatch(paymentMethodSlice.actions.saveSystemPaymentMethods(options));
    },
    getSystemPaymentMethods: async (options: { page: string; size: string }): Promise<void> => {
      await dispatch(getSystemPaymentMethods(options));
    },
    createSystemPaymentMethod: async (model: Omit<IPaymentMethod, 'id'>): Promise<void> => {
      await dispatch(createSystemPaymentMethod(model));
    },
    updateSystemPaymentMethod: async (model: IPaymentMethod): Promise<void> => {
      await dispatch(updateSystemPaymentMethod(model));
    },
    deleteSystemPaymentMethod: async (id: number): Promise<void> => {
      await dispatch(deleteSystemPaymentMethod(id));
    },
    getPartnerPaymentMethods: async (): Promise<
      { data: { data: IPartnerPaymentMethod; count: number } } | undefined
    > => {
      const partnerPaymentMethods = await dispatch(getPartnerPaymentMethods());
      return partnerPaymentMethods.payload as Promise<
        { data: { data: IPartnerPaymentMethod; count: number } } | undefined
      >;
    },
    getPosPaymentMethods: async (
      id: number,
    ): Promise<{ data: { data: IPartnerPaymentMethod; count: number } } | undefined> => {
      const partnerPaymentMethods = await dispatch(getPosPaymentMethods(id));
      return partnerPaymentMethods.payload as Promise<
        { data: { data: IPartnerPaymentMethod; count: number } } | undefined
      >;
    },
    getPartnerEnabledPaymentMethods: async (): Promise<
      { data: { data: IPartnerPaymentMethod; count: number } } | undefined
    > => {
      const partnerPaymentMethods = await dispatch(getPartnerPaymentMethods());
      return partnerPaymentMethods.payload as Promise<
        { data: { data: IPartnerPaymentMethod; count: number } } | undefined
      >;
    },
    savePartnerPaymentMethods: (options: ISaveOptions<IPartnerPaymentMethod>): void => {
      dispatch(paymentMethodSlice.actions.savePartnerPaymentMethods(options));
    },
    createPartnerPaymentMethod: async (model: Partial<IPartnerPaymentMethod>): Promise<void> => {
      await dispatch(createPartnerPaymentMethod(model));
    },
    updatePartnerPaymentMethod: async (model: Partial<IPartnerPaymentMethod>): Promise<void> => {
      await dispatch(updatePartnerPaymentMethod(model));
    },
    deletePartnerPaymentMethod: async (id: number): Promise<void> => {
      await dispatch(deletePartnerPaymentMethod(id));
    },
    getLocalPartnerPaymentMethodById: (id: number) =>
      state.partnerPaymentMethods.data.find((paymentMethod) => paymentMethod.id === id),
    getPartnerPaymentMethodById: async (id: number): Promise<{ data: IPartnerPaymentMethod } | undefined> => {
      const partnerPaymentMethod = await dispatch(getPartnerPaymentMethodById(id));
      return partnerPaymentMethod.payload as Promise<{ data: IPartnerPaymentMethod } | undefined>;
    },
  };

  return [state, actions];
};

export { paymentMethodSlice };

export default usePaymentMethod;
