import { FC, ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

export interface IRoute {
  path?: string;
  element: FC;
  routes?: IRoute[];
  permissions?: CashierPermissionsEnum[];
}

interface SwitchRouterProps {
  routes: IRoute[];
  existPermission?: CashierPermissionsEnum;
}

const renderRoute = (route: IRoute, existPermission: CashierPermissionsEnum | undefined): ReactNode => {
  const { path, routes, element: Element, permissions } = route;

  if (permissions && !permissions.some((r) => r === existPermission)) {
    console.warn('return');
    return;
  }

  return (
    <Route key={path} path={path} element={<Element />}>
      {Array.isArray(routes) && routes.map((i) => renderRoute(i, existPermission))}
    </Route>
  );
};

export const SwitchRouter: FC<SwitchRouterProps> = ({ routes, existPermission }) => (
  <Routes>{routes.map((i) => renderRoute(i, existPermission))}</Routes>
);

export default SwitchRouter;
